"use strict";

class SSAnalyticsService {

    constructor($window, SSHttpService, SSUtilService, SSAlertService) {
        const self = this;

        self.$window = $window;

        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAlertService = SSAlertService;

        // Constants
        self.GROUP_VIEWS = 'views';
        self.GROUP_SHARES = 'shares';
        self.GROUP_SHARECALLBACKS = 'sharecallbacks';
        self.GROUP_ENQUIRIES = 'enquiries';
        self.GROUP_DOWNLOADS = 'downloads';

        self.EVENT_VIEW_PROPERTY = 'views_property';
        self.EVENT_VIEW_STACK = 'views_stack';
        self.EVENT_VIEW_UNIT = 'views_unit';
        self.EVENT_VIEW_DRONE = 'views_drone';
        self.EVENT_VIEW_UNIT_3D = 'views_unit_3d';
        self.EVENT_VIEW_PROPERTY_3D = 'views_property_3d';

        self.EVENT_SHARED_BY_DEVELOPER = 'shares_by_developer';
        self.EVENT_SHARED_BY_BROKER = 'shares_by_broker';
        self.EVENT_SHARED_BY_BUYER = 'shares_by_buyer';
        self.EVENT_SHARED_BY_UNKNOWN = 'shares_by_unknown';

        self.EVENT_SHARECALLBACK_RECEIVED = 'sharecallbacks_received';

        self.EVENT_ENQUIRY_INITIATED = 'enquiries_initiated';
        self.EVENT_ENQUIRY_VERIFIED = 'enquiries_verified';

        self.EVENT_DOWNLOAD_PROPERTY_DOCUMENT = 'downloads_property_document';

        self.ANALYTICS_EVENT_VIEWS_DMS_DASHBOARD = 'views_dms_dashboard';
        self.ANALYTICS_EVENT_VIEWS_DMS_EOI = 'views_dms_eoi';
        self.ANALYTICS_EVENT_VIEWS_DMS_SALES = 'views_dms_sales';
        self.ANALYTICS_EVENT_VIEWS_DMS_APPLICATIONS = 'views_dms_applications';
        self.ANALYTICS_EVENT_VIEWS_DMS_PAYMENTS = 'views_dms_payments';
        self.ANALYTICS_EVENT_VIEWS_DMS_LIVE_STACK_VIEW = 'views_dms_live_stack_view';
        self.ANALYTICS_EVENT_VIEWS_DMS_PROPERTIES = 'views_dms_properties';
        self.ANALYTICS_EVENT_VIEWS_DMS_PROPERTY = 'views_dms_property';
        self.ANALYTICS_EVENT_VIEWS_DMS_REPORTS = 'views_dms_reports';
        self.ANALYTICS_EVENT_VIEWS_DMS_EMAILS = 'views_dms_emails';
        self.ANALYTICS_EVENT_VIEWS_DMS_ARMS = 'views_dms_arms';
        self.ANALYTICS_EVENT_VIEWS_DMS_INTEREST = 'views_dms_interest';
        self.ANALYTICS_EVENT_VIEWS_DMS_PAYMENT_PLANS = 'views_dms_payment_plans';
        self.ANALYTICS_EVENT_VIEWS_DMS_API_CONFIGURATION = 'views_dms_api_configuration';
        self.ANALYTICS_EVENT_VIEWS_DMS_API_SALES = 'views_dms_api_sales';
        self.ANALYTICS_EVENT_VIEWS_DMS_ANALYTICS = 'views_dms_analytics';
        self.ANALYTICS_EVENT_VIEWS_DMS_USER_INVITES = 'views_dms_user_invites';

        self.init();
    }

    init() {
        const self = this;
    }

    // Load All Charts
    //-------------------------------------------

    loadCharts() {
        const self = this;

        // Views
        self.drawTotalViewsByProjectChart();
        self.drawTopUnitViewsChart();
        self.drawViewsByLocationChart();
        self.drawViewsByDeviceChart();

        self.drawDroneViewsByProjectChart();
        self.draw3dViewsByProjectChart();

        // Shares
        self.drawTotalSharesByUserChart();
        self.drawTotalSharesByProjectChart();

        self.drawDeveloperSharesByProjectChart();
        self.drawAgentSharesByProjectChart();
        self.drawBuyerSharesByProjectChart();

        self.drawTopAgentsBySharesChart();
        self.drawTopUnitsBySharesChart();

        // Share Link Clicks
        self.drawShareCallbacksChart();

        // Leads
        self.drawTotalLeadsByProjectChart();
        self.drawTotalLeadsByUnitChart();
        self.drawTotalVerifiedLeadsChart();

        // Other
    }

    // Get Analytics Data
    //-------------------------------------------

    getAnalytics(params, callback) {
        const self = this;

        let url = 'analytics/query' + self.$window.location.search;

        //loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            //loading(false);

            if (response instanceof Error) {
                if (response.code == 401) {
                    self.SSAlertService.danger("Oops!", "Your session has expired. Please re-login to continue.");
                } else {
                    self.SSAlertService.danger("Oops!", "Something went wrong. Please contact support.");
                }
                return;
            }

            callback(response.data);
        });
    }

    // Save Analytics Data

    saveAnalyticEvent(params){
        let self = this;

        params.url = this.$window.location.href;
        self.SSHttpService.postAPIRequest('analytics/post', params).then(
            function (responseObj) {
                if (responseObj instanceof Error) {
                    console.error('Error while registering analytic event');
                }
            }
        );
    }

    // Load Data and Draw Charts
    //-------------------------------------------

    // Views

    drawTotalViewsByProjectChart() {
        const self = this;

        let container = jQuery('#total-views-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_VIEWS},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            if (responseData && responseData.analytics) {
                jQuery.each(responseData.analytics, function (i, e) {
                    let property = getFromArray(responseData.properties, 'id', e.property_id);

                    categories.push(
                        (property ? property.name : e.property_id)
                    );

                    data.push([e.count]);

                    colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
                });
            }

            self.drawBarChart(container, categories, data, colors);
        });
    }

    drawTopUnitViewsChart() {
        const self = this;

        let container = jQuery('#top-5-unit-views');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_VIEW_UNIT},
            "order_by": {"count": "desc"},
            "group_by": ["property_id", "unit_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);
                let unit = getFromArray(responseData.units, 'id', e.unit_id);

                categories.push(
                    (property ? property.name : e.property_id) +
                    ' ' +
                    (unit ? self.SSUtilService.getFullUnitNumber(unit) : e.unit_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawColumnChart(container, categories, data, colors);
        });
    }

    drawViewsByLocationChart() {
        const self = this;

        let container = jQuery('#views-by-locations');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_VIEWS},
            "order_by": {"count": "desc"},
            "group_by": ["country"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            if (responseData && responseData.analytics) {
                jQuery.each(responseData.analytics, function (i, e) {
                    categories.push(e.country ? e.country : 'Unknown');

                    data.push([e.count]);

                    colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
                });
            }

            self.drawBarChart(container, categories, data, colors);
        });
    }

    drawViewsByDeviceChart() {
        const self = this;

        let container = jQuery('#views-by-devices');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_VIEWS},
            "order_by": {"count": "desc"},
            "group_by": ["os"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                data.push({
                    name: (e.os ? e.os : 'Other'),
                    y: e.count,
                    sliced: i == 0 ? true : false
                });

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawPieChart(container, data, colors);
        });
    }


    drawDroneViewsByProjectChart() {
        const self = this;

        let container = jQuery('#drone-views-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_VIEW_DRONE},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            if (responseData && responseData.analytics) {
                jQuery.each(responseData.analytics, function (i, e) {
                    let property = getFromArray(responseData.properties, 'id', e.property_id);

                    categories.push(
                        (property ? property.name : e.property_id)
                    );

                    data.push([e.count]);

                    colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
                });
            }

            self.drawBarChart(container, categories, data, colors);
        });
    }

    draw3dViewsByProjectChart() {
        const self = this;

        let container = jQuery('#3d-views-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_VIEW_PROPERTY_3D},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            if (responseData && responseData.analytics) {
                jQuery.each(responseData.analytics, function (i, e) {
                    let property = getFromArray(responseData.properties, 'id', e.property_id);

                    categories.push(
                        (property ? property.name : e.property_id)
                    );

                    data.push([e.count]);

                    colors.push(i == 0 ? '#009933' : '#' + ((10 - i) * 111));
                });
            }

            self.drawBarChart(container, categories, data, colors);
        });
    }

    // Shares

    drawTotalSharesByUserChart() {
        const self = this;

        let container = jQuery('#total-shares-by-user');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_SHARES},
            "order_by": {"count": "desc"},
            "group_by": ["event"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function (i, e) {
                let eventName = '';
                if (e.event == self.EVENT_SHARED_BY_UNKNOWN) {
                    eventName = 'ANONYMOUS';
                } else if (e.event == self.EVENT_SHARED_BY_DEVELOPER) {
                    eventName = 'DEVELOPERS';
                } else if (e.event == self.EVENT_SHARED_BY_BROKER) {
                    eventName = 'AGENTS';
                } else if (e.event == self.EVENT_SHARED_BY_BUYER) {
                    eventName = 'BUYERS';
                } else {
                    return;
                }

                data.push({
                    name: eventName,
                    y: e.count,
                    sliced: i == 0 ? true : false
                });

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawPieChart(container, data, colors);
        });
    }

    drawTotalSharesByProjectChart() {
        const self = this;

        let container = jQuery('#total-shares-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_SHARES},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function (i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);

                data.push({
                    name: property.name,
                    y: e.count,
                    sliced: i == 0 ? true : false
                });

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawPieChart(container, data, colors);
        });
    }


    drawAgentSharesByProjectChart() {
        const self = this;

        let container = jQuery('#agent-shares-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_SHARED_BY_BROKER},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);

                categories.push(
                    (property ? property.name : e.property_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawBarChart(container, categories, data, colors);
        });
    }

    drawDeveloperSharesByProjectChart() {
        const self = this;

        let container = jQuery('#developer-shares-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_SHARED_BY_DEVELOPER},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);

                categories.push(
                    (property ? property.name : e.property_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawBarChart(container, categories, data, colors);
        });
    }

    drawBuyerSharesByProjectChart() {
        const self = this;

        let container = jQuery('#buyer-shares-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_SHARED_BY_BUYER},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);

                categories.push(
                    (property ? property.name : e.property_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawBarChart(container, categories, data, colors);
        });
    }


    drawTopAgentsBySharesChart() {
        const self = this;

        let container = jQuery('#top-5-agents-by-shares');
        if (!container.length) return;

        let params = {
            "filters": {"event": self.EVENT_SHARED_BY_BROKER},
            "order_by": {"count": "desc"},
            "group_by": ["user_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let user = getFromArray(responseData.users, 'id', e.user_id);

                categories.push(
                    (user ? user.display_name : e.user_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawBarChart(container, categories, data, colors);
        });
    }

    drawTopUnitsBySharesChart() {
        const self = this;

        let container = jQuery('#top-shares');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_SHARES},
            "order_by": {"count": "desc"},
            "group_by": ["property_id", "unit_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);
                let unit = getFromArray(responseData.units, 'id', e.unit_id);

                let name = property ? property.name : e.property_id;
                if (e.unit_id) {
                    name = name + ' ' + (unit ? self.SSUtilService.getFullUnitNumber(unit) : e.unit_id);
                }

                categories.push(name);
                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawBarChart(container, categories, data, colors);
        });
    }

    // Shared Link Clicks
    drawShareCallbacksChart() {
        const self = this;

        let container = jQuery('#share-callbacks');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_SHARECALLBACKS},
            "order_by": {"count": "desc"},
            "group_by": ["social_media"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                data.push({
                    name: (e.social_media ? e.social_media : 'Other'),
                    y: e.count,
                    sliced: i == 0 ? true : false
                });

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawPieChart(container, data, colors);
        });
    }

    // Leads

    drawTotalVerifiedLeadsChart() {
        const self = this;

        let container = jQuery('#total-verified-leads');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_ENQUIRIES},
            "order_by": {"count": "desc"},
            "group_by": ["event"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let event = '--';
                if (e.event == self.EVENT_ENQUIRY_INITIATED) {
                    event = 'INITIATED';
                } else if (e.event == self.EVENT_ENQUIRY_VERIFIED) {
                    event = 'VERIFIED';
                }

                categories.push(event);
                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawColumnChart(container, categories, data, colors);
        });
    }

    drawTotalLeadsByProjectChart() {
        const self = this;

        let container = jQuery('#leads-generated-by-project');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_ENQUIRIES, "event": self.EVENT_ENQUIRY_INITIATED},
            "order_by": {"count": "desc"},
            "group_by": ["property_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);

                categories.push(
                    (property ? property.name : e.property_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawColumnChart(container, categories, data, colors);
        });
    }

    drawTotalLeadsByUnitChart() {
        const self = this;

        let container = jQuery('#leads-generated-by-unit');
        if (!container.length) return;

        let params = {
            "filters": {"group": self.GROUP_ENQUIRIES, "event": self.EVENT_ENQUIRY_INITIATED},
            "order_by": {"count": "desc"},
            "group_by": ["property_id", "unit_id"],
            "offset": 0,
            "limit": 5
        };

        loading(true, 0, container);
        self.getAnalytics(params, function (responseData) {
            loading(false, 0, container);

            let categories = [];
            let colors = [];
            let data = [];

            jQuery.each(responseData.analytics, function(i, e) {
                let property = getFromArray(responseData.properties, 'id', e.property_id);
                let unit = getFromArray(responseData.units, 'id', e.unit_id);

                categories.push(
                    (property ? property.name : e.property_id) +
                    ' ' +
                    (unit ? self.SSUtilService.getFullUnitNumber(unit) : e.unit_id)
                );

                data.push([e.count]);

                colors.push(i == 0 ? '#009933' : '#'+((10-i)*111));
            });

            self.drawBarChart(container, categories, data, colors);
        });
    }

    // Others

    // Draw Charts
    //-------------------------------------------

    drawBarChart(container, categories, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data.');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'bar',
                margin: [0, 0, 0, 80],
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b> - <b>' + this.y + '</b>';
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    pointWidth: 30,
                    pointPadding: 5,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: true,
                        align: 'right',
                        color: '#FFFFFF',
                        x: -10
                    }
                }
            },
            colors: colors,
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: '',
                min: 0,
                allowDecimals: false,
                visible: false
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        });
    }

    drawColumnChart(container, categories, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data.');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'column',
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.x + '</b> - <b>' + this.y + '</b>';
                }
            },
            plotOptions: {
                series: {
                    colorByPoint: true,
                    pointWidth: 30,
                    pointPadding: 5,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: true,
                        align: 'top',
                        color: '#FFFFFF',
                        y: 25
                    }
                }
            },
            colors: colors,
            xAxis: {
                categories: categories,
            },
            yAxis: {
                title: '',
                min: 0,
                allowDecimals: false,
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            }
        });
    }

    drawPieChart(container, data, colors) {

        if (jQuery.isEmptyObject(data)) {
            container.html('No data.');
            return;
        }

        let clicked = false; // Workaround to detect dbclick

        container.highcharts({
            chart: {
                type: 'pie',
                events: {
                    click: function (event) {
                        if (clicked) {
                            //container.parent().toggleClass('container-fullscreen');
                            //this.reflow();
                            clicked = false;
                        } else {
                            clicked = true;
                            setTimeout(function() {
                                clicked = false;
                            }, 500);
                        }
                    }
                }
            },
            colors: colors,
            title: {
                text: ' '
            },
            subtitle: {
                //text: 'Hover on pie slices to view data'
            },
            tooltip: {
                pointFormat: '<b>{point.percentage:.0f}%</b> ({point.y})',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        connectorWidth: 1
                    }
                }
            },
            series: [{
                data: data
            }],
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            credits: {
                enabled: false
            }
        });
    }
    //-------------------------------------------
}

SSAnalyticsService.$inject = ['$window', 'SSHttpService', 'SSUtilService', 'SSAlertService'];
app.service('SSAnalyticsService', SSAnalyticsService);