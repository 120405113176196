"use strict";

class SSLmsDmsUtilService {
    /** WEB-2834 LMS 1.0 - Show SPA Expiry Date
     * This util contains common code across LMS and DMS **/

    constructor(SSUserService) {
        const self = this;

        self.SSUserService = SSUserService;
    }

    /*
        SPA Date Modal Functions - Start
     */
    executeSPAModal(parentCtrl, sale) {
        parentCtrl.hideModals();
        parentCtrl.sale = sale;
        parentCtrl.spaDeliveryDate ='';
        parentCtrl.spaDate = '';
        parentCtrl.exerciseDate = '';
        parentCtrl.success = '';
        parentCtrl.editExercise = false;
        parentCtrl.editSpa = false;
        parentCtrl.editSpaDelivery = false;
        parentCtrl.danger = false;
        // parentCtrl.selected_broker = broker;
        parentCtrl.$timeout(function() { $('.datepicker').datepicker({
            beforeShow: function (input, inst) {
                setTimeout(function () {
                    inst.dpDiv.css({
                        top: $(input).offset().top - $(document).scrollTop() + 35,
                        left: $(input).offset().left
                    });
                }, 0);
            },
            dateFormat: 'dd/mm/yy' }); });
        $('#executeSPAModal').modal('show');
    }

    editExerciseDate(parentCtrl)  {
        if(parentCtrl.sale) {
            parentCtrl.exerciseDate = moment(parentCtrl.sale.exercise_date, "YYYY-MM-DD").format( "DD/MM/YYYY");
        }
        parentCtrl.editExercise = true;
    }
    editSpaDeliveryDate(parentCtrl) {
        if(parentCtrl.sale) {
            parentCtrl.spaDeliveryDate = moment(parentCtrl.sale.spa_delivery_date, "YYYY-MM-DD").format( "DD/MM/YYYY");
        }
        parentCtrl.editSpaDelivery = true;
    }

    editSpaDate(parentCtrl) {
        if(parentCtrl.sale) {
            parentCtrl.spaDate = moment(parentCtrl.sale.spa_date, "YYYY-MM-DD").format( "DD/MM/YYYY");
        }
        parentCtrl.editSpa = true;
    }

    executeSpaDelivery(parentCtrl,sale) {
        const self = this;

        if(!parentCtrl.spaDeliveryDate) return;

        parentCtrl.success = '';
        if(parentCtrl.spaDeliveryDate) {
            if(parentCtrl.sale.spa_date)
            {
                var spaDate = moment(parentCtrl.sale.spa_date, "YYYY-MM-DD").format( "DD/MM/YYYY");
                if (moment(parentCtrl.spaDeliveryDate, "DD/MM/YYYY") > moment(spaDate, "DD/MM/YYYY")) {
                    parentCtrl.danger = "Please enter SPA delivery date smaller than SPA receipt date";
                    return;
                }
            }
            var res = parentCtrl.spaDeliveryDate.split("/", 3);
            parentCtrl.spaDeliveryDate = res[2] + '-' + res[1] + '-' + res[0];
        }else {
            return;
        }
        parentCtrl.success = '';

        let apiSig = (self.SSUserService.isLawyer())? 'lawyer' : 'developer';
        let url = 'sec/'+apiSig+'/sales/'+sale.id+'/enter-spa-delivery';
        let params = {
            'saleId': sale.id,
            'spa_delivery_date': parentCtrl.spaDeliveryDate
        };

        loading(true);
        parentCtrl.SSHttpService.putAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                parentCtrl.SSAlertService.parseAndDisplayError(response);
                return;
            }
            parentCtrl.danger = '';
            //Update sale status
            parentCtrl.spaStatus = response.data.sale;
            sale.status = parentCtrl.spaStatus.status;
            sale.spa_delivery_date = parentCtrl.spaStatus.spa_delivery_date;
            parentCtrl.spaDeliveryDate = '';
            parentCtrl.editSpaDelivery = false;
            parentCtrl.success = 'Success! SPA delivery date saved successfully.';
            parentCtrl.$timeout(function() { $('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' }); });
        });
    }

    executeSpa(parentCtrl, sale) {
        const self = this;

        if(!parentCtrl.spaDate) return false;

        parentCtrl.success = '';
        if(parentCtrl.spaDate!=='' && parentCtrl.spaDate!=null) {
            var spa_delivery_date = moment(parentCtrl.sale.spa_delivery_date, "YYYY-MM-DD").format( "DD/MM/YYYY");
            if(moment(parentCtrl.spaDate, "DD/MM/YYYY") < moment(spa_delivery_date, "DD/MM/YYYY"))
            {
                parentCtrl.danger ="Please enter SPA receipt date greater than SPA delivery date";
                return;
            }
            var res = parentCtrl.spaDate.split("/", 3);
            parentCtrl.spaDate = res[2] + '-' + res[1] + '-' + res[0];
        }else {
            return;
        }
        parentCtrl.success = '';

        let apiSig = (self.SSUserService.isLawyer())? 'lawyer' : 'developer';
        let url = 'sec/'+apiSig+'/sales/'+sale.id+'/enter-spa';
        let params = {
            'spa_date': parentCtrl.spaDate
        };

        loading(true);
        parentCtrl.SSHttpService.putAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                parentCtrl.SSAlertService.parseAndDisplayError(response);
                parentCtrl.spaDate = '';
                return;
            }
            parentCtrl.danger = '';
            //Update sale status
            parentCtrl.spaStatus = response.data.sale;
            sale.status=parentCtrl.spaStatus.status;
            sale.spa_date = parentCtrl.spaStatus.spa_date;
            if(sale.spa_date)
            {
                sale.actual_expiry_date = parentCtrl.getExpiryDate(sale.spa_date);
            }
            parentCtrl.spaDate = '';
            parentCtrl.editSpa = false;
            parentCtrl.success = 'Success! SPA date saved successfully.';
            parentCtrl.$timeout(function() { $('.datepicker').datepicker({ dateFormat: 'dd/mm/yy' }); });
        });
    }

    executeExercise(parentCtrl, sale) {
        const self = this;

        if(!parentCtrl.exerciseDate) return;

        parentCtrl.success = '';
        var spaDate = moment(parentCtrl.sale.spa_date, "YYYY-MM-DD").format( "DD/MM/YYYY");
        parentCtrl.OTPService.getVerified(parentCtrl.OTPService.TYPE_SESSION_2FA, function(data){
            if(parentCtrl.exerciseDate!='' && parentCtrl.exerciseDate!=null) {
                if(moment(parentCtrl.exerciseDate, "DD/MM/YYYY") > moment(parentCtrl.today, "DD/MM/YYYY")) {
                    parentCtrl.danger = "Please enter SPA exercise date not greater than today";
                    return;
                }

                if(moment(parentCtrl.exerciseDate, "DD/MM/YYYY") <  moment(moment(parentCtrl.sale.spa_delivery_date, "YYYY-MM-DD").format( "DD/MM/YYYY"), "DD/MM/YYYY")) {
                    parentCtrl.danger ="Please enter SPA exercise date greater than SPA delivery date";
                    return;
                }

                if(moment(parentCtrl.exerciseDate, "DD/MM/YYYY") < moment(moment(parentCtrl.sale.spa_date, "YYYY-MM-DD").format( "DD/MM/YYYY"), "DD/MM/YYYY")) {
                    parentCtrl.danger = "Please enter SPA exercise date greater than SPA receipt date";
                    return;
                }
                var res = parentCtrl.exerciseDate.split("/", 3);
                parentCtrl.exerciseDate = res[2] + '-' + res[1] + '-' + res[0];
            }
            parentCtrl.success = '';

            let apiSig = (self.SSUserService.isLawyer())? 'lawyer' : 'developer';
            let url = 'sec/'+apiSig+'/sales/'+ sale.id +'/exercise';
            let params = {
                'saleId': sale.id,
                'exercise_date': parentCtrl.exerciseDate
            };

            loading(true);
            parentCtrl.SSHttpService.putAPIRequest(url, params).then(function (response) {
                loading(false);

                if (response instanceof Error) {
                    if (response.sub_code == 305015 || response.sub_code == 412002) {
                        parentCtrl.SSAlertService.danger("Please Note:", "This sale has been updated by another user. You will not be able to make further updates to this sale.");
                        parentCtrl.exerciseDate = "";
                        return;
                    }
                    parentCtrl.SSAlertService.parseAndDisplayError(response);
                    parentCtrl.exerciseDate = "";
                    return;
                }
                parentCtrl.danger ='';
                //Update sale status
                parentCtrl.exerciseStatus = response.data.sale;
                sale.status=parentCtrl.exerciseStatus.status;
                sale.exercise_date = parentCtrl.exerciseStatus.exercise_date;
                parentCtrl.exerciseDate = '';
                parentCtrl.editExercise = false;
                parentCtrl.success = 'Success! Exercise date saved successfully.';
            });
        });
    }
    /*  SPA Date Modal Functions - End */

}

SSLmsDmsUtilService.$inject = ['SSUserService'];
app.service('SSLmsDmsUtilService', SSLmsDmsUtilService);