"use strict";

class SSSearchService {
  constructor($log, $localStorage, SSUtilService, SSAppService) {
    const self = this;
    self.$log = $log;
    self.$localStorage = $localStorage;
    self.SSUtilService = SSUtilService;
    self.SSAppService = SSAppService;
    self.searchProperties = SSUtilService.defineStorageDefault('def_search_properties');
    self.countries = self.SSAppService.getCountries();


    if (!self.searchProperties.districts) {
      self.searchProperties.districts = [];
    }
    if (!self.searchProperties.mrt_stations) {
      self.searchProperties.mrt_stations = [];
    }
    if (!self.searchProperties.schools) {
      self.searchProperties.schools = [];
    }
    if (!self.searchProperties.developers) {
      self.searchProperties.developers = [];
    }

    self.DEFAULT_OFFSET = 0;
    self.DEFAULT_LIMIT = 20;
    self.SQ_TYPE = {
      PROPERTIES: 1,
      UNITS: 2
    };

    self.prices = self.getPriceArray();
    self.pricesReverse = self.getPriceArray();
    self.psfs = self.getPsfArray();
    self.psfsReverse = _.clone(self.psfs).reverse();
    self.areas = self.getAreasArray();
    self.areasReverse = _.clone(self.areas).reverse();
    self.bedrooms = self.getBedroomsArray();
    self.bedroomsReverse = _.clone(self.bedrooms).reverse();
    self.bathrooms = self.getBathroomsArray();
    self.bathroomsReverse = _.clone(self.bathrooms).reverse();
    self.tenures = self.getTenures();

    self.userIsOnSearchResult = false;
    self.searchTriggered = false;
  }

  resetSq() {
    const self = this;
    self.searchProperties.apiSqType = self.SQ_TYPE.PROPERTIES;
  }

  isSearchingForProperties() {
    const self = this;
    return self.searchProperties.apiSqType == self.SQ_TYPE.PROPERTIES;
  }

  isSearchingForUnits() {
    const self = this;
    return self.searchProperties.apiSqType == self.SQ_TYPE.UNITS && self.searchProperties.apiPropertyId;
  }

  /**
   * Returned validated and formatted url for search API according to API requirements.
   *
   * return url string for api call
   */
  getSearchUrlForApi() {
    const self = this;

    //validate sq type
    if (!self.isSearchingForProperties() && !self.isSearchingForUnits()) {
      self.searchProperties.apiSqType = self.SQ_TYPE.PROPERTIES;
    }

    let url = 'search';

    //type of search and pagination
    url += '?sq_type=' + self.searchProperties.apiSqType;

    if (self.isSearchingForUnits()) {

      url += '&property_id=' + self.searchProperties.apiPropertyId;

      //validate pagination
      if (!self.searchProperties.apiUnitOffset || !Number.isInteger(self.searchProperties.apiUnitOffset)) {
        self.searchProperties.apiUnitOffset = self.DEFAULT_OFFSET;
      }
      if (!self.searchProperties.apiUnitLimit || !Number.isInteger(self.searchProperties.apiUnitLimit)) {
        self.searchProperties.apiUnitLimit = self.DEFAULT_LIMIT;
      }

      //set pagination
      url += '&offset=' + self.searchProperties.apiUnitOffset;
      url += '&limit=' + self.searchProperties.apiUnitLimit;
    } else {

      //validate pagination
      if (!self.searchProperties.apiPropertyOffset || !Number.isInteger(self.searchProperties.apiPropertyOffset)) {
        self.searchProperties.apiPropertyOffset = self.DEFAULT_OFFSET;
      }
      if (!self.searchProperties.apiPropertyLimit || !Number.isInteger(self.searchProperties.apiPropertyLimit)) {
        self.searchProperties.apiPropertyLimit = self.DEFAULT_LIMIT;
      }

      //set pagination
      url += '&offset=' + self.searchProperties.apiPropertyOffset;
      url += '&limit=' + self.searchProperties.apiPropertyLimit;
    }

    //price
    if (self.searchProperties.apiMinPrice) {
      url += '&min_price=' + self.searchProperties.apiMinPrice;
    }
    if (self.searchProperties.apiMaxPrice) {
      url += '&max_price=' + self.searchProperties.apiMaxPrice;
    }

    //size
    if (self.searchProperties.apiMinSize) {
      url += '&min_size=' + self.searchProperties.apiMinSize;
    }
    if (self.searchProperties.apiMaxSize) {
      url += '&max_size=' + self.searchProperties.apiMaxSize;
    }
    //bedrooms
    if (self.searchProperties.apiMinBedroom) {
      url += '&min_bedroom=' + self.searchProperties.apiMinBedroom;
    }
    if (self.searchProperties.apiMaxBedroom) {
      url += '&max_bedroom=' + self.searchProperties.apiMaxBedroom;
    }

    //bathroom
    if (self.searchProperties.apiMinBathroom) {
      url += '&min_bathroom=' + self.searchProperties.apiMinBathroom;
    }
    if (self.searchProperties.apiMaxBathroom) {
      url += '&max_bathroom=' + self.searchProperties.apiMaxBathroom;
    }

    //psf
    if (self.searchProperties.apiMinPsf) {
      url += '&min_psf=' + self.searchProperties.apiMinPsf;
    }
    if (self.searchProperties.apiMaxPsf) {
      url += '&max_psf=' + self.searchProperties.apiMaxPsf;
    }
    if (self.searchProperties.apiCountry) {
      url += '&country=' + self.searchProperties.apiCountry;
    }
    if (self.searchProperties.apiCountry_s) {
          url += '&country=' + self.searchProperties.apiCountry;
        }
    if (self.searchProperties.apiLat) {
      url += '&lat=' + self.searchProperties.apiLat;
    }

    if (self.searchProperties.apiLong) {
      url += '&long=' + self.searchProperties.apiLong;
    }

    if (self.searchProperties.apiPropertyType) {
      url += '&property_type=' + self.searchProperties.apiPropertyType;
    }

    if (self.searchProperties.apiUnitType) {
      url += '&unit_type=' + self.searchProperties.apiUnitType;
    }

    if (self.searchProperties.apiDistricts) {
      url += '&districts=' + self.searchProperties.apiDistricts;
    }

    if (self.searchProperties.apiKeyword) {
      url += '&keyword=' + self.searchProperties.apiKeyword;
    }

    if (self.searchProperties.apiTenure) {
      url += '&tenure=' + self.searchProperties.apiTenure;
    }

    if (self.searchProperties.districts) {
      url += '&districts=' + self.searchProperties.districts.join(',');
    }

    if (self.searchProperties.mrt_stations) {
      url += '&mrt_stations=' + self.searchProperties.mrt_stations.join(',');
    }

    if (self.searchProperties.schools) {
      url += '&schools=' + self.searchProperties.schools.join(',');
    }

    if (self.searchProperties.developers) {
      url += '&developers=' + self.searchProperties.developers.join(',');
    }

    return url;
  }

  /**
   * Attaches/Adds/Updates/Deletes all the selected parameters to provided url as it will be reflected in browser.
   * @returns string for browser
   */
  getQuickSearchUrlWithParams() {
    const self = this;
    let resultsUrl = '/search';

    //build url with params
    if (self.searchProperties.apiCountry) {
        resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'country', self.searchProperties.apiCountry);
    }
    if (self.searchProperties.apiDistrict) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'districts', self.searchProperties.apiDistrict);
    }
    if (self.searchProperties.apiUnitType) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'unit_type', self.searchProperties.apiUnitType);
    }
    if (self.searchProperties.apiMinPrice) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'min_price', self.searchProperties.apiMinPrice);
    }
    if (self.searchProperties.apiMaxPrice) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'max_price', self.searchProperties.apiMaxPrice);
    }
    if (self.searchProperties.apiKeyword) {
              resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'keyword', self.searchProperties.apiKeyword);
    }

    return resultsUrl;
  }

  /**
   * Attaches/Adds/Updates/Deletes all the selected parameters to provided url as it will be reflected in browser.
   * @returns string for browser
   */
  getSearchUrlWithParams() {
    const self = this;

    let resultsUrl = self.getQuickSearchUrlWithParams();

    //build url with params
    if (self.searchProperties.apiSqType) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'sq_type', self.searchProperties.apiSqType);
    }
    if (self.isSearchingForUnits()) {
      if (self.searchProperties.apiPropertyId) {
        resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'property_id', self.searchProperties.apiPropertyId);
      }
    }
    if (self.searchProperties.apiKeyword) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'keyword', self.searchProperties.apiKeyword);
    }
    if (self.searchProperties.apiMinPsf) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'min_psf', self.searchProperties.apiMinPsf);
    }
    if (self.searchProperties.apiMaxPsf) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'max_psf', self.searchProperties.apiMaxPsf);
    }
    if (self.searchProperties.apiPropertyType) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'property_type', self.searchProperties.apiPropertyType);
    }
    if (self.searchProperties.apiMinSize) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'min_size', self.searchProperties.apiMinSize);
    }
    if (self.searchProperties.apiMaxSize) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'max_size', self.searchProperties.apiMaxSize);
    }
    if (self.searchProperties.apiCountry) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'country', self.searchProperties.apiCountry);
    }
    if (self.searchProperties.apiMinBedroom) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'min_bedroom', self.searchProperties.apiMinBedroom);
    }
    if (self.searchProperties.apiMaxBedroom) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'max_bedroom', self.searchProperties.apiMaxBedroom);
    }
    if (self.searchProperties.apiMinBathroom) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'min_bathroom', self.searchProperties.apiMinBathroom);
    }
    if (self.searchProperties.apiMaxBathroom) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'max_bathroom', self.searchProperties.apiMaxBathroom);
    }
    if (self.searchProperties.apiLat) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'lat', self.searchProperties.apiLat);
    }
    if (self.searchProperties.apiLong) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'long', self.searchProperties.apiLong);
    }
    if (self.searchProperties.apiPropertyOffset) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'propertiesOffset', self.searchProperties.apiPropertyOffset);
    }
    if (self.searchProperties.apiPropertyLimit) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'propertiesLimit', self.searchProperties.apiPropertyLimit);
    }
    if (self.searchProperties.apiUnitOffset) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'unitsOffset', self.searchProperties.apiUnitOffset);
    }
    if (self.searchProperties.apiUnitLimit) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'unitsLimit', self.searchProperties.apiUnitLimit);
    }
    if (self.searchProperties.apiTenure) {
      resultsUrl = self.SSUtilService.addOrUpdateUrlParam(resultsUrl, 'tenure', self.searchProperties.apiTenure);
    }

    return resultsUrl;
  }

  updateDistricts(checked, districtCode) {
    let self = this;

    let districts = self.searchProperties.districts;

    let index = districts.indexOf(districtCode);

    if (checked && index === -1) {
      districts.push(districtCode)
    } else if (!checked && index !== -1) {
      districts.splice(index, 1);
    }
  }

  isDistrictSelected(districtCode) {
    let self = this;

    return _.includes(self.searchProperties.districts, districtCode)
  }

  uncheckAllDistricts() {
    let self = this;

    self.searchProperties.districts = [];
  }

  emptyDistricts() {
    let self = this;

    return !self.searchProperties.districts || self.searchProperties.districts.length == 0;
  }

  updateMrtStations(checked, mrtStationId) {
    let self = this;

    let mrtStations = self.searchProperties.mrt_stations;

    let index = mrtStations.indexOf(mrtStationId);

    if (checked && index === -1) {
      mrtStations.push(mrtStationId)
    } else if (!checked && index !== -1) {
      mrtStations.splice(index, 1);
    }
  }

  isMrtStationSelected(mrtStationId) {
    let self = this;

    return _.includes(self.searchProperties.mrt_stations, mrtStationId)
  }

  uncheckAllMrtStations() {
    let self = this;

    self.searchProperties.mrt_stations = [];
  }

  emptyMrtStations() {
    let self = this;

    return !self.searchProperties.mrt_stations || self.searchProperties.mrt_stations.length == 0;
  }

  updateSchools(checked, schoolId) {
    let self = this;

    let schools = self.searchProperties.schools;

    let index = schools.indexOf(schoolId);

    if (checked && index === -1) {
      schools.push(schoolId)
    } else if (!checked && index !== -1) {
      schools.splice(index, 1);
    }
  }

  isSchoolSelected(schoolId) {
    let self = this;

    return _.includes(self.searchProperties.schools, schoolId)
  }

  uncheckAllSchools() {
    let self = this;

    self.searchProperties.schools = [];
  }

  emptySchools() {
    let self = this;

    return !self.searchProperties.schools || self.searchProperties.schools.length == 0;
  }

  updateDevelopers(checked, developerId) {
    let self = this;

    let developers = self.searchProperties.developers;

    let index = developers.indexOf(developerId);

    if (checked && index === -1) {
      developers.push(developerId)
    } else if (!checked && index !== -1) {
      developers.splice(index, 1);
    }
  }

  isDeveloperSelected(developerId) {
    let self = this;

    return _.includes(self.searchProperties.developers, developerId)
  }

  uncheckAllDevelopers() {
    let self = this;

    self.searchProperties.developers = [];
  }

  emptyDevelopers() {
    let self = this;

    return !self.searchProperties.developers || self.searchProperties.developers.length == 0;
  }

  hideAdvanceSearch() {
    const self = this;

    self.searchProperties.showAdvanceSearch = false;

    self.searchProperties.apiMinPsf = null;
    self.searchProperties.apiMaxPsf = null;
    self.searchProperties.apiPropertyType = null;
    self.searchProperties.apiMinSize = null;
    self.searchProperties.apiMaxSize = null;
    self.searchProperties.apiCountry = null;
    self.searchProperties.apiMinBedroom = null;
    self.searchProperties.apiMaxBedroom = null;
    self.searchProperties.apiMinBathroom = null;
    self.searchProperties.apiMaxBathroom = null;
    self.searchProperties.apiLat = null;
    self.searchProperties.apiLong = null;
    self.searchProperties.apiTenure = null;
    self.searchProperties.districts = [];
    self.searchProperties.mrt_stations = [];
    self.searchProperties.schools = [];
    self.searchProperties.developers = [];
  }

  showAdvanceSearch() {
    const self = this;

    self.searchProperties.showAdvanceSearch = true;
  }

  getBedroomsArray() {
    return [
      {
        "value": 1,
        "title": "1 Bedroom"
      },
      {
        "value": 2,
        "title": "2 Bedrooms"
      },
      {
        "value": 3,
        "title": "3 Bedrooms"
      },
      {
        "value": 4,
        "title": "4 Bedrooms"
      },
      {
        "value": 5,
        "title": "5+ Bedrooms"
      }
    ];
  }

  getBathroomsArray() {
    return [
      {
        "value": 1,
        "title": "1 Bathroom"
      },
      {
        "value": 2,
        "title": "2 Bathrooms"
      },
      {
        "value": 3,
        "title": "3 Bathrooms"
      },
      {
        "value": 4,
        "title": "4 Bathrooms"
      },
      {
        "value": 5,
        "title": "5+ Bathrooms"
      }
    ];
  }

  getAreasArray() {
    return [
      {
        "value": 500,
        "title": "500 sqft (46 sqm)"
      },
      {
        "value": 750,
        "title": "750 sqft (70 sqm)"
      },
      {
        "value": 1000,
        "title": "1,000 sqft (93 sqm)"
      },
      {
        "value": 1200,
        "title": "1,200 sqft (112 sqm)"
      },
      {
        "value": 1500,
        "title": "1,500 sqft (139 sqm)"
      },
      {
        "value": 2000,
        "title": "2,000 sqft (186 sqm)"
      },
      {
        "value": 2500,
        "title": "2,500 sqft (232 sqm)"
      },
      {
        "value": 3000,
        "title": "3,000 sqft (279 sqm)"
      },
      {
        "value": 4000,
        "title": "4,000 sqft (372 sqm)"
      },
      {
        "value": 5000,
        "title": "5,000 sqft (465 sqm)"
      },
      {
        "value": 7500,
        "title": "7,500 sqft (697 sqm)"
      },
      {
        "value": 10000,
        "title": "10,000 sqft (929 sqm)"
      }
    ];
  }

  getPsfArray() {
    return [
      200,
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      1000,
      1250,
      1500,
      1750,
      2000,
      2500,
      3000,
      3500,
      4000,
      4500,
      5000
    ];
  }

  getPriceArray() {
    return [
      200000,
      300000,
      400000,
      500000,
      600000,
      700000,
      800000,
      900000,
      1000000,
      1250000,
      1500000,
      2000000,
      2500000,
      3000000,
      4000000,
      5000000,
      6000000,
      8000000,
      10000000,
      15000000,
      20000000,
      30000000,
      50000000
    ];
  }

  getTenures() {
    return [
      {
        "value": "Freehold",
        "title": "Freehold"
      },
      {
        "value": "999",
        "title": "999 Years"
      },
      {
        "value": "99",
        "title": "99 Years"
      },
      {
        "value": "60",
        "title": "60 Years"
      }
    ];
  }
}

SSSearchService.$inject = ['$log', '$localStorage', 'SSUtilService', 'SSAppService'];
app.service('SSSearchService', SSSearchService);