"use strict";

class SSStackviewService {

    constructor(SSStorageService) {
        const self = this;

        self.SSStorageService = SSStorageService;
    }

    /** WEB-2860 Stack View: Need to add more details in each cell - START **/
    openStackviewSettingsModal(){
        $('#stack-view-settings-modal').modal('show');
    }

    getStackViewSettings( stackviewSettings ) {
        const self = this;

        let localStackviewSettings = null;
        if( self.SSStorageService.getFromLocalStorage("stackview_settings") ){
            /* Retrieve Stack View Settings from local storage */
            try{
                localStackviewSettings = JSON.parse( self.SSStorageService.getFromLocalStorage("stackview_settings") );
                /* If there are existing settings then update retrieved settings in existing settings object */
                if(stackviewSettings && Object.keys(localStackviewSettings)){
                    Object.keys(localStackviewSettings).forEach( key => {
                        stackviewSettings[key] = localStackviewSettings[key];
                    })
                    return stackviewSettings;
                }
            }catch (e){ console.error(e); }
        }
        return localStackviewSettings;
    }

    updateStackViewSettings(stackviewSettings) {
        const self = this;

        try { /* Save stack view settings in local storage */
            self.SSStorageService.saveToLocalStorage("stackview_settings", JSON.stringify(stackviewSettings));
            //self.$localStorage['stackview_settings'] = JSON.stringify(self.stackviewSettings);
        }catch (e) { console.error(e); }
    }

    removeTypePrefix(floorPlanTxt){
        if(floorPlanTxt){
            floorPlanTxt = floorPlanTxt.replace(/type/ig, '').trim();
        }

        return floorPlanTxt;
    }
    /** WEB-2860 Stack View: Need to add more details in each cell - END **/

}

SSStackviewService.$inject = ['SSStorageService'];
app.service('SSStackviewService', SSStackviewService);
