"use strict";

class SSStorageService {

    constructor($localStorage, $sessionStorage) {
        const self = this;

        self.$localStorage = $localStorage;
        self.$sessionStorage = $sessionStorage;
    }

    // Local Storage

    saveToLocalStorage(key, val) {
        this.$localStorage[key] = val;
    }

    deleteFromLocalStorage(key) {
        delete this.$localStorage[key];
    }

    getFromLocalStorage(key) {
        return this.$localStorage[key];
    }

    // Local Storage

    // Session Storage

    saveToSessionStorage(key, val) {
        this.$sessionStorage[key] = val;
    }

    deleteFromSessionStorage(key) {
        delete this.$sessionStorage[key];
    }

    getFromSessionStorage(key) {
        return this.$sessionStorage[key];
    }

    // Session Storage

}

SSStorageService.$inject = ['$localStorage', '$sessionStorage'];
app.service('SSStorageService', SSStorageService);