'use strict';

class SSAwsService {
    constructor($log, SSUtilService, SSHttpService, SSAlertService, Upload) {
        const self = this;

        self.$log = $log;
        self.SSUtilService = SSUtilService;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;
        self.Upload = Upload;

        self.appServiceObject = SSUtilService.defineStorageDefault('def_app_service');

        self.init();
    }

    init() {
        const self = this;
    }

    upload(file, policyUrlOrObject, callback) {
        const self = this;

        if (typeof policyUrlOrObject == "string") {
            self.policy(policyUrlOrObject, function (policyDocument) {
                self._upload(file, policyDocument, callback);
            });
        } else {
            self._upload(file, policyUrlOrObject, callback);
        }
    }

    uploadAPIRequest(file, policyUrlOrObject, callback, fileName = null) {
        const self = this;

        if (typeof policyUrlOrObject == "string") {
            self.policyAPIRequest(policyUrlOrObject, function (policyDocument) {
                self._upload(file, policyDocument, callback, fileName);
            });
        } else {
            self._upload(file, policyUrlOrObject, callback, fileName);
        }
    }

    policy(url, callback) {
        const self = this;

        self.SSHttpService.getRequest(url).then(function (response) {
            if (response instanceof Error) {
                if (response.code == 401) {
                    self.SSAlertService.danger('Oops!', 'Your session has expired. Please re-login to continue.');
                } else if (response.code == 500) {
                    self.SSAlertService.danger('Oops!', 'Something went wrong. Please contact support.');
                }
                return;
            }
            callback(response.data.policy);
        });
    }

    policyAPIRequest(url, callback) {
        const self = this;

        self.SSHttpService.getAPIRequest(url).then(function (response) {
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            callback(response.data.policy);
        });
    }

    _upload(file, policy, callback, fileName = null) {
        const self = this;

        let randomName = self.SSUtilService.getRandomName()
        let filepath = policy.path + '/' + (fileName ? fileName : randomName );
        let ext = self.SSUtilService.getFileExtension(file.name);

        if(!filepath.endsWith("." + ext)){
            filepath = ext ? filepath + '.' + ext : '';
        }

        let data = {
            key: filepath, // the key to store the file on S3, could be file name or customized
            AWSAccessKeyId: policy.key,
            acl: policy.acl, // sets the access to the uploaded file in the bucket: private, public-read, ...
            policy: policy.document, // base64-encoded json policy (see article below)
            signature: policy.signature, // base64-encoded signature based on policy string (see article below)
            'Content-Type': '', // content type of the file (NotEmpty)
            filename: filepath, // this is needed for Flash polyfill IE8-9
            file: file,
            randomname: randomName,
            extension:ext
        };

        self.Upload.upload({
            url: self.appServiceObject.serverConfig.assets_host,
            method: 'POST',
            headers: {'Authorization': undefined}, // Don't send Authorization headers to S3
            data: data
        }).then(function (response) {
            // response.config.data.key
            // response.config.data.file.name
            callback(response);
        }, function (response) {
            console.error('Error: ' + response.status);
            self.SSAlertService.danger('Oops!', 'Something went wrong. Please contact support.');
        }, function (evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            console.log('Progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
        });
    }

    post(url, params) {
        const self = this;

        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                console.error('Error while adding/updating asset file.');
                console.error(response);

                if (response.code == 401) {
                    self.SSAlertService.danger('Oops!', 'Your session has expired. Please re-login to continue.');
                } else if (response.code == 500) {
                    self.SSAlertService.danger('Oops!', 'Something went wrong. Please contact support.');
                }
                return;
            }

            console.log('Asset file has been uploaded successfully.');
            self.SSAlertService.success('Success!', 'File uploaded successfully.');
        });
    }

}

SSAwsService.$inject = ['$log', 'SSUtilService', 'SSHttpService', 'SSAlertService', 'Upload'];
app.service('SSAwsService', SSAwsService);