"use strict";

class SSSaleDiscountsService {

    constructor(SSConfigService, SSHttpService, SSAlertService) {
        const self = this;

        self.SSConfigService = SSConfigService;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;

        self.init();
    }

    init() {
        const self = this;

        self.DISCOUNT_TYPE_STANDARD = 10;
        self.property_discounts = [];
    }

    applyStandardDiscount(sale_id, unit_id, standard_discount){
        const self = this;

        if(!(sale_id || unit_id)){
            console.log('Invalid parameter(s) provided. Can\'t apply standard discount');
            return;
        }

        if(standard_discount && self.isDiscountApplicable(unit_id, standard_discount)){

            let url = 'sec/sales/' + sale_id + '/discounts/' + standard_discount.id + '/apply';

            return {
                then: function (callback) {
                    self.SSHttpService.postAPIRequest(url).then(function (response){
                        callback(response);
                    });
                }
            }
        } else {
            return null;
        }
    }

    getStandardDiscount(property_discounts) {
        const self = this;

        let standard_discount = null;

        if (property_discounts) {

            $.each(property_discounts, function(index, property_discount) {
                if(property_discount.type == self.DISCOUNT_TYPE_STANDARD){
                    standard_discount = property_discount;
                    return false;
                }
              // if (property_discount.is_active) {
              //   let start_date = moment(property_discount.availability_start_date);
              //   let end_date = moment(property_discount.availability_end_date);
              //   let today_date = moment();

              //   if (end_date.isSameOrAfter(today_date, 'day') && today_date.isSameOrAfter(start_date, 'day')) {
              //       if(property_discount.type == self.DISCOUNT_TYPE_STANDARD){
              //           standard_discount = property_discount;
              //           return false;
              //       }
              //   }
              // }
            });
        }

        return standard_discount;
    }

    getStandardDiscounts(property_discounts) {
        const self = this;

        let standard_discounts = [];

        if (property_discounts) {
            $.each(property_discounts, function (index, property_discount) {
                if (self.isActiveDiscount(property_discount)) {
                    if (property_discount.type == self.DISCOUNT_TYPE_STANDARD) {
                        standard_discounts.push(property_discount);
                    }
                }
            });
        }

        return standard_discounts;
    }

    isActiveDiscount(property_discount) {
        const self = this;

        return property_discount.is_active
            && self.isValidDiscount(property_discount);
    }

    isValidDiscount(property_discount) {
        const self = this;

        let validity = false;

        if (property_discount) {
            // Convert 'Start/End' dates to 'locale' date-time string for time comparison
            let start_date = self.formatUtcToLocalDate(property_discount.availability_start_date);
            let end_date = self.formatUtcToLocalDate(property_discount.availability_end_date);
            let today_date = moment(moment().format('YYYY-MM-DD HH:mm:ss'));

            // As the second parameter determines the precision, 
            // and not just a single value to check, using day will check for year, month and day.
            // If nothing is passed to moment#isBefore, it will default to the current time.
            // REFERENCE: https://momentjscom.readthedocs.io/en/latest/moment/05-query/01-is-before/

            if (end_date.isSameOrAfter(today_date, 'day')
                && today_date.isSameOrAfter(start_date, 'day')
                && start_date.diff(today_date, 'minutes') <= 0
            ) {
                validity = true;
            }
        }

        return validity;
    }

    formatUtcToLocalDate(date){
        const self = this;

        var local = date;

        try{
            if(typeof date === 'string'){
                // Convert 'date-time' string to UTC 'date-time' object
                var stillUtc = moment.utc(date).toDate();

                // Now convert UTC to local 'date-time' object
                var local = moment(moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss'));  
            }
        }
        catch(ex){
            console.log(ex);
        }

        return local;
    }
    
    getPropertyDiscounts(property_id){
        const self = this;

        let url = 'properties/' + property_id + '/property-discounts';

        return {
            then: function(callback){
                self.SSHttpService.getAPIRequest(url).then(function(response){
                    callback(response);
                });
            }
        }
    }

    isDiscountApplicable(unit_id, discount){
        const self = this;

        if(!discount.unit_ids || discount.unit_ids.toLowerCase() == "all"){
            return true;
        }

        let unit_ids = discount.unit_ids.split(',');

        if($.inArray(unit_id.toString(), unit_ids) > -1){
            return true;
        }

        return false;
    }

}

SSSaleDiscountsService.$inject = ['SSConfigService', 'SSHttpService', 'SSAlertService'];
app.service('SSSaleDiscountsService', SSSaleDiscountsService);
