"use strict";

class SSAlertService {

    constructor($rootScope) {
        const self = this;

        self.$rootScope = $rootScope;

        self.TYPE_SUCCESS = 'success';
        self.TYPE_INFO = 'info';
        self.TYPE_WARNING = 'warning';
        self.TYPE_DANGER = 'danger';

        self.clear();
    }

    clear() {
        const self = this;

        self.type = null;
        self.title = null;
        self.message = null;
    }

    show(type, title, message) {
        const self = this;

        if (title && !message) {
            message = title;
            title = null;
        }

        if (!(type && message)) return;

        if (!title) {
            if (type == self.TYPE_SUCCESS) {
                title = 'Success!';
            } else if (type == self.TYPE_INFO) {
                title = 'Info!';
            } else if (type == self.TYPE_WARNING) {
                title = 'Warning!';
            } else if (type == self.TYPE_DANGER) {
                title = 'Oops!';
            }
        }

        self.type = type;
        self.title = title;
        self.message = message;

        $('html,body').animate({ scrollTop: jQuery('#__ss_alert_message__').offset().top }, 'slow');
    }

    showPop(type, title, message) {
        const self = this;

        if (title && !message) {
            message = title;
            title = null;
        }

        if (!(type && message)) return;

        if (!title) {
            if (type == self.TYPE_SUCCESS) {
                title = 'Success!';
            } else if (type == self.TYPE_INFO) {
                title = 'Info!';
            } else if (type == self.TYPE_WARNING) {
                title = 'Warning!';
            } else if (type == self.TYPE_DANGER) {
                title = 'Oops!';
            }
        }

        self.type = type;
        self.titlePop = title;
        self.messagePop = message;
    }

    success(title, message) {
        this.show(this.TYPE_SUCCESS, title, message);
    }

    successPop(title, message) {
        this.showPop(this.TYPE_SUCCESS, title, message);
    }

    info(title, message) {
        this.show(this.TYPE_INFO, title, message);
    }

    warning(title, message) {
        this.show(this.TYPE_WARNING, title, message);
    }

    danger(title, message) {
        this.show(this.TYPE_DANGER, title, message);
    }

    parseAndDisplayError(response) {
        const self = this;

        console.log(response);

        let code = null;

        if (response) {
            if (response.code) {
                code = response.code;
            } else if (response.data && response.data.code) {
                code = response.data.code;
            }
        }

        if (!code) return;

        self.$rootScope.single_login_error = false;
        
        if (code == 401) {
            self.$rootScope.$broadcast('UserLoginRequiredEvent');

            if (response.sub_code == 401001 || response.sub_code == 401003 || response.sub_code == 401004) {
                self.danger('Opps!', 'You have been logged out from this session. Please login to continue.');
                self.$rootScope.single_login_error = true;
            } else {
                self.danger('Unauthorised!', 'You cannot perform this action. Please make sure you are logged in and has required permissions.');
            }
        } else if (code == 403) {
            self.danger('Forbidden!', 'This action is not allowed.');
        } else if (code == 404) {
            self.danger('Not Found!', 'The data/action request you are making is not available.');
        } else if (code == 405) {
            self.danger('Not Allowed!', 'Something is really not right. Please contact support.');
        } else if (code == 409) {
            self.danger('Conflict!', 'Another entry already exists with this value.');
        } else if (code == 412) {
            self.danger('Invalid Params!', 'Please fill-in the valid information.');
        } else {
            self.danger('Oops!', 'Something went wrong. Please contact support.');
        }

        if (response.data && response.data.message) {
            self.warning('Warning!', response.data.message);
        }
    }
}

SSAlertService.$inject = ['$rootScope'];
app.service('SSAlertService', SSAlertService);