"use strict";

class SSConfirmationService {

    constructor() {
        const self = this;

        self.TYPE_DEFAULT = 1;
        self.TYPE_OK = 2;
        self.TYPE_OK_CANCEL = 3;
        self.TYPE_YES_NO = 4;
        self.TYPE_YES = 5;
        self.CANCEL_PROCEED = 6;

        self.OPTION_NO = 0;
        self.OPTION_YES = 1;

        self.init();
    }

    init() {
        const self = this;

        self.show = false;
        self.callback = null;

        self.title = null;
        self.message = null;
        self.type = null;
    }

    getConfirmed(title, message, type, callback) {
        const self = this;

        self.title = title;
        self.message = message;
        self.type = type;
        self.callback = callback;

        self._show(true);
    }

    _confirm(selectedOption) {
        const self = this;

        self._show(false);

        self.callback(selectedOption);
    }

    _show(status) {
        const self = this;

        self.show = status;
        $('#confirmationModal').modal(status ? 'show' : 'hide');
    }

}

app.service('SSConfirmationService', SSConfirmationService);