"use strict";

class SSDisclaimerService {

    constructor($localStorage, $sessionStorage, SSHttpService) {
        const self = this;

        self.$localStorage = $localStorage;
        self.$sessionStorage = $sessionStorage;

        self.SSHttpService = SSHttpService;

        self.STORAGE_KEY = 'DISCLAIMERS';

        self.STORAGE_NONE = 0;
        self.STORAGE_SESSION = 1;
        self.STORAGE_LOCAL = 2;
        self.disclaimerAccepted= false;

        self.MESSAGE_PROPERTY3D_VIEW = '' +
            'These computer generated and rendered images are designed to provide an indication of the exterior façade and surroundings and may be subject to change.'+
            '\n\nBy proceeding, you acknowledge and accept that they are computer generated images with limitations on accuracy especially in respect to, among other things, scale, colour, texture of materials, and landscaping.' +
            '\n\nNote: Minimum system requirements may apply and it may not support all devices.'+
            '\n\nPlease click "I Accept" if you accept and wish to proceed.';
        self.MESSAGE_UNIT3D_VIEW = ''+
            'These computer generated and rendered images are designed to provide a general overview of the layout of a home.'+
            '\n\nBy proceeding, you acknowledge and accept that the plans and dimensions may not be perfectly accurate as they are computer generated images with limitations on accuracy especially in respect to, among other things, scale, colour, texture of materials and equipment.'+
            '\n\nFurther, the project developer typically delivers such new homes without any moveable, furnishing or lightings whatsoever, and the furnishings shown are purely for illustrative purposes.'+
            '\n\nThe dimensions of the home delivered upon construction are subject to the customary construction allowance governed by law.'+
            '\n\nNote: Minimum system requirements may apply and it may not support all devices.'+
            '\n\nPlease click "I Accept" if you accept and wish to proceed.';
        self.MESSAGE_DRONE_IMAGE_VIEW = ''+
            'These images have been captured and displayed to provide you with potential external views from selected points within the development.'+
            '\n\nWhile we strive to ensure accuracy of the views and heights corresponding to storey levels of the buildings, you acknowledge and accept their limitations on accuracy especially with constant changes to the surroundings, and that, importantly, the images are intended to give only an indication of the general views of the surroundings in the general orientation the images were taken and do not represent the actual views from any particular home.'+
            '\n\nNote: Minimum system requirements may apply and it may not support all devices.'+
            '\n\nPlease click "I Accept" if you accept and wish to proceed.';
        self.MESSAGE_UNIT_PHOTOS_VIEW = ''+
            'These visual representations, illustrations, photographs, art renderings and other graphic representations and references are intended to portray only artist impressions of the specific units within the development.' +
            '\n\nWhile we strive to ensure accuracy of the views and heights corresponding to storey levels of the buildings, you acknowledge and accept their limitations on accuracy especially with constant changes to the surroundings, and that, importantly, the images are intended to give only an indication of the general views of the surroundings in the general orientation the images were taken and do not represent the actual views from any particular home.' +
            '\n\nNote: Minimum system requirements may apply and it may not support all devices.' +
            '\n\nPlease click "I Accept" if you accept and wish to proceed.';
        self.MESSAGE_PROPERTY_VIEW = ''+
            'While every reasonable care has been taken in preparing this digital brochure, neither the developer nor Showsuite will be held responsible for any inaccuracy or omissions.' +
            '\n\nVisual representations, illustrations, photographs, art renderings, and other graphic representations and references are intended to portray only artist’s impressions of the development and / or décor suggestions and cannot be regarded as representations of fact.' +
            '\n\nAll plans and models are not to scale unless expressly stated and are subject to any amendments which are required or approved by the relevant authorities. All areas and other measurements are approximate only and are subject to final survey.' +
            '\n\nThe property is subject to final inspection by the relevant authorities to comply with the current code of practice. All information, specifications, renderings, visual representations and plans are current at the time of publishing and are subject to changes as may be required by the developer and/or the competent authorities.' +
            '\n\nAll statements are believed to be correct but are not to be regarded as statements or representation of facts.'+
            '\n\nThe Sale & Purchase Agreement embodies all the terms and conditions between developer and the purchaser and supersedes and cancels in all respects all previous representations, warranties, promises, inducements or statements of intention, whether written or oral made by the developer and/or Showsuite which are not embodied in the Sales and Purchase Agreement.'+
            '\n\nNote: Minimum system requirements may apply and it may not support all devices.'+
            '\n\nPlease click "I Accept" if you accept and wish to proceed.';

        self.init();
    }

    init() {
        const self = this;

        self.show = false;
        self.callback = null;
        self.key = null;
        self.message = null;
        self.type = null;
    }

    getAccepted(key, message, type, callback) {
        const self = this;

        self.key = key;
        self.message = message;
        self.type = type;
        self.callback = callback;

        let storage = null;

        if (self.type == self.STORAGE_LOCAL) {
            storage = self.$localStorage;
        } else if (self.type == self.STORAGE_SESSION) {
            storage = self.$sessionStorage;
        }

        if (storage) {
            let disclaimers = storage[self.STORAGE_KEY];
            if (disclaimers && disclaimers.indexOf(self.key) != -1) {
                self.callback();
                return;
            }
        }

        self.showModal(true);
    }

    markAccepted() {
        const self = this;

        let storage = null;

        if (self.type == self.STORAGE_LOCAL) {
            storage = self.$localStorage;
        } else if (self.type == self.STORAGE_SESSION) {
            storage = self.$sessionStorage;
        }

        if (storage) {
            let disclaimers = storage[self.STORAGE_KEY];
            if (!disclaimers) {
                disclaimers = [];
            }
            if (disclaimers && disclaimers.indexOf(self.key) == -1) {
                disclaimers.push(self.key);
                storage[self.STORAGE_KEY] = disclaimers;
                self.disclaimerAccepted = true;
            }
        }

        self.showModal(false);
        self.callback();
    }

    showModal(status) {
        const self = this;

        self.show = status;
        $('#disclaimerModal').modal(status ? 'show' : 'hide');
    }

}

SSDisclaimerService.$inject = ['$localStorage', '$sessionStorage', 'SSHttpService'];
app.service('SSDisclaimerService', SSDisclaimerService);