"use strict";

class TWOFAService {

    constructor($sce, $timeout, SSHttpService, SSAlertService) {
        const self = this;

        self.sce = $sce;
        self.$timeout = $timeout;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;

        self.init();
    }

    init() {
        const self = this;

        self.callback = null;
        self.apiPath = null;
        self.callParams = null;
        self.resend_code_delay_timer = 60 * 1000; // 60 seconds
    }

    verify2FA(params, callback, two_fa_method = 'phone', apiPath = null) {
        const self = this;

        if (typeof callback === "function") {
            self.callback = callback;
        }
        self.apiPath = apiPath;

        self.errors = {};

        self.email_only = false;
        self.prefix_2fa = params.prefix_2fa ? params.prefix_2fa : '';
        self.title = self.sce.trustAsHtml(params.title ? params.title : "2FA Authentication");

        let _sent_to = (two_fa_method == 'phone') ? "phone number " : "email address ";

        if (params.sent_to) {
            _sent_to += "(" + params.sent_to + ")";
        }

        let message = "<p>Please enter below the OTP you received on your registered ";
        message += "<span id='otp_sent_to' style='display: inline;'> " + _sent_to + "</span>";
        message += " with Showsuite.</p><p>Enter the 6-digit verification code within 5 minutes.</p>";

        if (params.message) {
            message = params.message;
        }

        // Append image
        let message_html = "<div class='row'><div class='col-md-2' style='margin-left:-15px;'>";
        if (two_fa_method == 'email') {
            message_html += "<img id='otp_alert_img' src='/images/otp-email.png' style='width: 132px; height: 161px;' alt='Email OTP'>";
        } else {
            message_html += "<img id='otp_alert_img' src='/images/sms-alert.png' style='width: 132px; height: 161px;' alt='SMS OTP'>";
        }
        message_html += "</div><div class='col-md-1'></div><div class='col-md-9' style='margin-left:15px;'>" + message + "</div></div>";

        self.message = self.sce.trustAsHtml(message_html);

        self.check2FAparams = params.check2FAparams ? params.check2FAparams : null;

        if (params.resendCode) {
            self.resendCode = params.resendCode;
            self.setResendCodeExpiration(null, two_fa_method);
        }
        self.callParams = params.callParams;


        if (two_fa_method == 'email') {
            self.email_only = true;
        }

        self.showModal(true);
    }

    showModal(status) {
        const self = this;

        self.twoFAverificationModal = status;
        self.params = {};
        if (status) {
            $('#twoFAverificationModal').modal({
                show: true,
                backdrop: 'static',
                keyboard: false
            });
        } else {
            if ($('#twoFAverificationModal').is(':visible')) {
                $('#twoFAverificationModal').modal('hide');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            }
        }
    }

    verifyCode() {
        const self = this;

        self.errors = {};

        if (!self.params.twofa_code || self.params.twofa_code.length < 6) {
            self.errors.twofa_code = 'Please enter a 6 digit verification code';
            return;
        }

        let url = (self.apiPath ? self.apiPath : 'verify2/') + self.prefix_2fa + self.params.twofa_code;

        let handleResponse = function (response) {
            loading(false);
            if (response instanceof Error) {
                self.errors.twofa_code = response.message;
                return;
            }
            self.showModal(false);
            self.callback(response);
        };

        loading(true);
        if (self.check2FAparams) {
            self.check2FAparams.twofa_code = self.prefix_2fa + self.params.twofa_code;
            self.SSHttpService.postAPIRequest('2fa', self.check2FAparams).then(handleResponse);
        } else {
            self.SSHttpService.getAPIRequest(url, self.callParams).then(handleResponse);
        }

    }

    resend2faCode(two_fa_method) {
        const self = this;

        if (self.disableResendCode) {
            return;
        }

        let url = self.resendCode.url;
        let params = self.resendCode.subParams ? self.resendCode.subParams : {};
        params["2fa_method"] = two_fa_method;

        let handleResponse = function (response) {
            loading(false);

            if (response.sub_code == 401002) {
                // TOKEN_SENT_SUCCESSFULLY
                if (response && response.data && response.data.prefix_2fa) {
                    self.prefix_2fa = response.data.prefix_2fa;
                }
                self.setResendCodeExpiration(response, two_fa_method);
                return;
            }
            // Have error, something wrong
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response && response.data && response.data.prefix_2fa) {
                self.prefix_2fa = response.data.prefix_2fa;
            }

            self.setResendCodeExpiration(response, two_fa_method);
        };

        loading(true);
        if (self.resendCode.requestMethod == 'GET') {
            self.SSHttpService.getAPIRequest(url, params).then(handleResponse);
        } else if (self.resendCode.requestMethod == 'PUT') {
            self.SSHttpService.putAPIRequest(url, params).then(handleResponse);
        } else if (self.resendCode.requestMethod == 'POST') {
            self.SSHttpService.postAPIRequest(url, params).then(handleResponse);
        }
    }

    setResendCodeExpiration(response, two_fa_method = 'phone') {
        const self = this;

        self.resendCode.two_fa_method = two_fa_method;
        self.disableResendCode = true;
        self.$timeout(function () {
            self.disableResendCode = false;
        }, self.resend_code_delay_timer);

        let otp_sent_to = (response && response.data && response.data.sent_to) ? ' (' + response.data.sent_to + ')' : '';

        // Switch image if 2fa_method is email
        if (two_fa_method == 'email') {
            $('#otp_alert_img').attr('src', '/images/otp-email.png');
            $('#otp_sent_to').text('email address' + otp_sent_to);
        } else if (response) {
            $('#otp_alert_img').attr('src', '/images/sms-alert.png');
            $('#otp_sent_to').text('phone number' + otp_sent_to);
        }

        var seconds = 60;
        $("#resend_code_timer").text(seconds + " sec.");

        // Update the count down every 1 second
        var x = setInterval(function () {

            seconds--;

            // If the count down is over, write some text 
            if (seconds <= 0) {
                $("#resend_code_timer").text("");
                clearInterval(x);
            } else {
                $("#resend_code_timer").text(seconds + " sec.");
            }

        }, 1000);
    }

    updateOpacity() {
        const self = this;

        if (self.params.twofa_code && self.params.twofa_code.length > 0) {
            $('#two_fa_input').css({'opacity': '1'});
        } else {
            $('#two_fa_input').css({'opacity': '.4'});
        }
    }

}

TWOFAService.$inject = ['$sce', '$timeout', 'SSHttpService', 'SSAlertService'];
app.service('TWOFAService', TWOFAService);