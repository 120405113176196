"use strict";

class SettingsService {

    constructor(SSConfigService, SSHttpService, SSAlertService, SSUtilService) {
        const self = this;

        self.SSConfigService = SSConfigService;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;
        self.SSUtilService = SSUtilService;

        self.init();
    }

    init() {
        const self = this;
        self.developerSettings = null;
    }

    getPropertySetting(url, setting_name, callback) {
        const self = this;

        if (!url || !setting_name) {
            console.log('Invalid parameter(s) provided. Can\'t fetch settings.');
            return;
        }

        loading(true);
        this.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                return;
            }

            let singleSetting = [];
            if (response.data && response.data.property_settings) {
                // Get Specified Settings
                singleSetting = self.getSetting(response.data.property_settings, setting_name);
            }

            let data = {};
            data['success'] = true;
            data[setting_name] = singleSetting;

            callback(data);
        });
    }

    getSetting(settings, setting_name) {
        const self = this;

        if (!(settings && setting_name)) {
            return null;
        }

        let setting = {};

        try {
            setting = settings.find(x => x.name === setting_name);

            if (setting
                && setting.value
                && typeof setting.value == "string"
                && self.SSUtilService.isJSON(setting.value)
            ) {
                setting.value = JSON.parse(setting.value);
            }
        } catch (ex) {
            console.log(ex);
        }

        return setting;
    }

    formatAppointmentSettings(settings){
        const self = this;

        if(settings){
            if(settings.value && settings.value.blackout_days){
                $.each(settings.value.blackout_days, function(index, blackout_day){
                    if(blackout_day.date){
                        blackout_day.date = moment(moment(blackout_day.date, 'YYYY-MM-DD HH:mm:ss')).utc().format('DD/MM/YYYY');
                    }
                    if(blackout_day.start_time && blackout_day.end_time){
                        blackout_day.start_time = (moment.utc(blackout_day.start_time, 'HH:mm')).local().format('HH:mm');
                        blackout_day.end_time = (moment.utc(blackout_day.end_time, 'HH:mm')).local().format('HH:mm');
                    }
                });
            }

            if(settings.value && settings.value.slot_times){
                $.each(settings.value.slot_times, function(index, slot){
                    if(slot.start_time && slot.end_time){
                        slot.start_time = (moment.utc(slot.start_time, 'HH:mm')).local().format('HH:mm');
                        slot.end_time = (moment.utc(slot.end_time, 'HH:mm')).local().format('HH:mm');
                    }
                });
            }
        }

        return settings;
    }

    getDeveloperSettings(callback) {
        const self = this;
        if(self.developerSettings) {
            callback(self.developerSettings);
            return;
        }

        let url = 'sec/developer/settings';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.data && response.data.developer_settings) {
                self.developerSettings = response.data.developer_settings;
                if(callback){
                    callback(self.developerSettings);
                }
            }
        });
    }

}

SettingsService.$inject = ['SSConfigService', 'SSHttpService', 'SSAlertService', 'SSUtilService'];
app.service('SettingsService', SettingsService);