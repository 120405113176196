"use strict";

class SSEoiDiscountsService {

    constructor(SSConfigService, SSHttpService, SSAlertService) {
        const self = this;

        self.SSConfigService = SSConfigService;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;

        self.init();
    }

    init() {
        const self = this;

        self.DISCOUNT_TYPE_STANDARD = 10;
        self.property_discounts = [];
    }

    applyStandardDiscount(eoi_id, unit_id, standard_discount){
        const self = this;

        if(!(eoi_id || unit_id)){
            console.log('Invalid parameter(s) provided. Can\'t apply standard discount');
            return;
        }

        if(standard_discount && self.isDiscountApplicable(unit_id, standard_discount) && self.isValidDiscount(standard_discount)){

            let url = 'sec/eois/' + eoi_id + '/discounts/' + standard_discount.id + '/apply';

            let params = {
                unit_id: unit_id,
            };

            return {
                then: function (callback) {
                    self.SSHttpService.postAPIRequest(url, params).then(function (response){
                        callback(response);
                    });
                }
            }
        }
    }

    getStandardDiscounts(property_discounts) {
        const self = this;

        let standard_discounts = [];

        if (property_discounts) {
            $.each(property_discounts, function (index, property_discount) {
                if (self.isActiveDiscount(property_discount)) {
                    if (property_discount.type == self.DISCOUNT_TYPE_STANDARD) {
                        standard_discounts.push(property_discount);
                    }
                }
            });
        }

        return standard_discounts;
    }

    getPropertyDiscounts(property_id) {
        const self = this;

        let url = 'properties/' + property_id + '/property-discounts';

        return {
            then: function (callback) {
                self.SSHttpService.getAPIRequest(url).then(function (response) {
                    callback(response);
                });
            }
        }
    }

    getDiscountsByUnit(property_discounts, unit){
        const self = this;

        let discounts = [];
        let standard_discounts = self.getStandardDiscounts(property_discounts);

        if (standard_discounts && unit) {
            $.each(standard_discounts, function(i, discount){
                if(self.isDiscountApplicable(unit.id, discount) 
                    && self.isValidDiscount(discount)){

                    discounts.push(discount);
                }
            });
        }

        return discounts;
    }

    isDiscountApplicable(unit_id, discount) {
        if (!discount.unit_ids || discount.unit_ids.toLowerCase() === "all") {
            return true; // Discount is applicable to all units
        }

        return $.inArray(unit_id.toString(), discount.unit_ids.split(',')) > -1;
    }

    isActiveDiscount(property_discount) {
        const self = this;

        return property_discount.is_active
            && self.isValidDiscount(property_discount);
    }

    isValidDiscount(property_discount) {
        const self = this;

        let validity = false;

        if (property_discount) {
            // Convert 'Start/End' dates to 'locale' date-time string for time comparison
            let start_date = self.formatUtcToLocalDate(property_discount.availability_start_date);
            let end_date = self.formatUtcToLocalDate(property_discount.availability_end_date);
            let today_date = moment(moment().format('YYYY-MM-DD HH:mm:ss'));

            // As the second parameter determines the precision, 
            // and not just a single value to check, using day will check for year, month and day.
            // If nothing is passed to moment#isBefore, it will default to the current time.
            // REFERENCE: https://momentjscom.readthedocs.io/en/latest/moment/05-query/01-is-before/

            if (end_date.isSameOrAfter(today_date, 'day')
                && today_date.isSameOrAfter(start_date, 'day')
                && start_date.diff(today_date, 'minutes') <= 0
            ) {
                validity = true;
            }
        }

        return validity;
    }

    formatUtcToLocalDate(date){
        const self = this;

        var local = date;

        try{
            if(typeof date === 'string'){
                // Convert 'date-time' string to UTC 'date-time' object
                var stillUtc = moment.utc(date).toDate();

                // Now convert UTC to local 'date-time' object
                var local = moment(moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss'));  
            }
        }
        catch(ex){
            console.log(ex);
        }

        return local;
    }
}

SSEoiDiscountsService.$inject = ['SSConfigService', 'SSHttpService', 'SSAlertService'];
app.service('SSEoiDiscountsService', SSEoiDiscountsService);