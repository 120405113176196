"use strict";

class SSFirebaseMessagingService  {

    constructor(SSUtilService ,SSHttpService) {
        const self = this;

        self.SSUtilService = SSUtilService;
        self.SSHttpService = SSHttpService;

        self.permission = false;

        try{
            self.setToastrOptions();

            self.messaging = firebase.messaging();

            self.messaging.onMessage(function (payload) {
                toastr.info(payload.notification.body, payload.notification.title);
            });


        }
        catch (e) {
            console.log("Error while initializing messaging Object" + e);
        }
    }

    setFirebaseToken(FIREBASE_VAPID_KEY){
        const self = this;
        try {
            if(self.messaging && self.permission) {
                self.messaging.usePublicVapidKey(FIREBASE_VAPID_KEY+"");
                self.messaging.getToken().then((token) => {
                    self.SSUtilService.saveToLocalStorage('FCM_TOKEN', token);
                    let url = 'pushnotifications/register';
                    let date = new Date();
                    let params = {
                        'id': self.SSUtilService.localStorageUUID(),
                        'os_type': 'web',
                        'timezone': date.getTimezoneOffset(),
                        'device_token': token
                    };
                    self.SSHttpService.postAPIRequest(url, params).then(
                        function (responseObj) {
                            // loading(false);
                            if (responseObj instanceof Error) {
                                console.log('Error on adding token to push notification');
                                return;
                            }
                        }
                    );
                }).catch((err) => {
                    console.log('Unable to retrieve refreshed token ', err);
                });

            }
        }catch (e) {
            console.log("Error while adding token call" + e);
        }
    }

    setToastrOptions() {
        toastr.options.positionClass = "toast-top-right";
        toastr.options.closeButton = true;
        toastr.options.showMethod = 'slideDown';
        toastr.options.hideMethod = 'slideUp';
        toastr.options.timeOut = '6000';
        toastr.options.newestOnTop = true;
        //toastr.options.newestOnTop = false;
        toastr.options.progressBar = true;
    };

    requestNotificationPermission(){
        const self = this;
        try {
            if (self.messaging) {
                self.messaging.requestPermission().then(() => {
                    console.log("got permission for showing notification.");
                    self.permission = true;
                }).catch((err) => {
                    console.log("error in getting token for notification.", err);
                });
            }
        }catch (e) {
            console.log("Error in Firebase Message", err);
        }
    }

}

SSFirebaseMessagingService.$inject = ['SSUtilService', 'SSHttpService'];
app.service('SSFirebaseMessagingService', SSFirebaseMessagingService);