"use strict";

class SSAdminPasswordService {

    /**
     * DON'T ADD ANY SERVICE HERE
     */
    constructor() {
        const self = this;

        self.init();
    }

    init() {
        const self = this;

        self.callback = null;
        self.verified = null;
        self.params = {};
    }

    getVerified(callback) {
        const self = this;

        self.callback = callback;

        if (!self.verified) {
            self.showModal(true);
        } else {
            self.callback();
        }
    }

    showModal(status) {
        const self = this;

        self.params = {};
        self.errors = {};

        self.adminPasswordModal = status;
        $('#adminPasswordModal').modal(status ? 'show' : 'hide');
    }

    verifyCode() {
        const self = this;

        self.errors = {};

        if (self.params.admin_password !== 'Showsuite171113') {
            self.errors.admin_password = "Invalid Password";
            return;
        }

        self.showModal(false);

        self.verified = true;
        self.callback();
    }

}

app.service('SSAdminPasswordService', SSAdminPasswordService);