"use strict";

class OTPService {

    constructor(SSHttpService, SSAlertService) {
        const self = this;

        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;

        self.TYPE_ENABLE_2FA = 1;
        self.TYPE_LOGIN = 2;
        self.TYPE_BOOKNOW = 3;
        self.TYPE_SESSION_2FA = 4;

        self.TOKEN_SENT_SUCCESSFULLY = 1;
        self.TOKEN_WAS_ALREADY_SENT = 2;
        self.TOKEN_SENT_ERROR = 3;
        self.TOKEN_MISSING_REQUIRED_PARAMS = 4;

        self.init();
    }

    init() {
        const self = this;

        self.user = null;
        self.callback = null;
        self.params = null;
    }

    getVerified(type, callback, message = null) {
        const self = this;

        if (typeof callback === "function") {
            self.callback = callback;
        } else if (typeof type === "function") {
            self.callback = type;
            type = callback;
        }

        if (self.user && self.user.twofa_verified) {
            self.callback();
            return;
        }

        self.userMessage = "";

        if(message){
            self.message = message;
        }
        else{
            self.message = 'Please enter below the Showsuite Login One-Time-Password you received on your registered phone number.';
        }

        loading(true);
        self.SSHttpService.getAPIRequest('sec/2fa').then(
            function (response) {
                loading(false);

                self.SSAlertService.clear();

                if (response instanceof Error) {
                    if (response.code == 401) {
                        self.SSAlertService.danger("Oops!", "Your session has expired. Please re-login to continue.");
                    } else if (response.code == 500) {
                        if (response.sub_code == self.TOKEN_MISSING_REQUIRED_PARAMS) {
                            self.SSAlertService.danger("Oops!", "Unable to send One-Time-Password. Looks like you haven't updated phone number in your profile.");
                        } else if (response.sub_code == self.TOKEN_SENT_ERROR) {
                            self.SSAlertService.danger("Oops!", "Unable to send One-Time-Password. Please try again later.");
                        } else {
                            self.SSAlertService.danger("Oops!", "Something went wrong. Please contact support.");
                        }
                    }
                    return;
                }

                self.user = response.data.user;

                if(response && response.data && response.data.prefix_2fa){
                    self.prefix_2fa = response.data.prefix_2fa;
                }
                    
                if (response.sub_code == self.TOKEN_SENT_SUCCESSFULLY || response.sub_code == self.TOKEN_WAS_ALREADY_SENT) {
                    self.showModal(true, type);
                } else {
                    self.callback();
                }
            }
        );
    }

    showModal(status, type) {
        const self = this;

        self.params = {
            email: self.user.email,
            twofa_type: type
        };
        self.code = '';
        self.errors = {};

        self.oneTimePasswordModal = status;
        $('#oneTimePasswordModal').modal(status ? 'show' : 'hide');
    }

    verifyCode() {
        const self = this;

        self.errors = {};

        loading(true);
        self.SSHttpService.postAPIRequest('2fa', self.params).then(
            function (response) {
                loading(false);

                if (response instanceof Error) {
                    self.errors.twofa_code = "Invalid Code";
                    return;
                }

                self.showModal(false);

                self.callback(response.data);
            }
        );
    }

}

OTPService.$inject = ['SSHttpService', 'SSAlertService'];
app.service('OTPService', OTPService);