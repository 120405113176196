"use strict";

class SSSaleRemarksService {

    constructor(SSAlertService, SSHttpService, SSUtilService) {
        const self = this;

        self.SSAlertService = SSAlertService;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
    }

    showAddSaleRemarksModal(sale, callback) {
        const self = this;

        self.saleForAddRemarks = sale;
        self.callback = callback;
        self.saleRemarks = '';

        self.SSUtilService.showModal('#addSaleRemarksModal');
    }

    hideAddSaleRemarksModal() {
        const self = this;

        self.saleForAddRemarks = null;
        self.saleRemarks = null;

        self.SSUtilService.showModal('#addSaleRemarksModal', false);
    }
    
    addSaleRemarks() {
        const self = this;

        let url = 'sec/sales/' + self.saleForAddRemarks.id + '/remarks';
        let params = {
            remarks: self.saleRemarks
        };

        loading(true);
        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            let saleHistory = response.data;

            self.SSAlertService.success('Remarks added successfully.');
            if (self.saleForAddRemarks) {
                self.saleForAddRemarks.last_remarks = saleHistory.remarks;
            }

            if (self.callback && typeof self.callback === "function") {
                self.callback(saleHistory);
            }

            self.hideAddSaleRemarksModal(); // This line should be after the local sale object update
        });
    }

}

SSSaleRemarksService.$inject = ['SSAlertService', 'SSHttpService', 'SSUtilService'];
app.service('SSSaleRemarksService', SSSaleRemarksService);
