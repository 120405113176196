"use strict";

class SSAppointmentService {

    constructor(SSConfigService, SSHttpService, SSUtilService, SSAlertService, SSConfirmationService) {
        const self = this;

        self.SSConfigService = SSConfigService;
        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAlertService = SSAlertService;
        self.SSConfirmationService = SSConfirmationService;

        self.users = [];
        self.buyers = [];
        self.brokers = [];
        self.agencies = [];
        self.APPOINTMENTS_DATA = {};

        self.init();
    }

    init() {
        const self = this;

        self.STEP_NUMBER = 0;
        self.SHOW_STEP = {1: false, 2: false, 3: false};

        self.getAgencies();
        self.initStatuses();
    }

    getPropertySettings(url, callback) {
        const self = this;
 
        if(!url){
            console.log('Invalid url to get property settings.');
            return;
        }

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if(response.data && response.data.property_settings){

                // Get Visitor Appointment Settings
                self.appointment_settings = self.getSetting(response.data.property_settings, 'visitor_appointment_settings');
            
                if(self.appointment_settings){
                    if(self.appointment_settings.value && self.appointment_settings.value.blackout_days){
                        $.each(self.appointment_settings.value.blackout_days, function(index, blackout_day){
                            if(blackout_day.date){
                                blackout_day.date = moment(moment(blackout_day.date, 'YYYY-MM-DD HH:mm:ss')).utc().format('DD/MM/YYYY');
                            }
                            if(blackout_day.start_time && blackout_day.end_time){
                                blackout_day.start_time = (moment.utc(blackout_day.start_time, 'HH:mm')).local().format('HH:mm');
                                blackout_day.end_time = (moment.utc(blackout_day.end_time, 'HH:mm')).local().format('HH:mm');
                            }
                        });
                    }

                    if(self.appointment_settings.value && self.appointment_settings.value.slot_times){
                        $.each(self.appointment_settings.value.slot_times, function(index, slot){
                            if(slot.start_time && slot.end_time){
                                slot.start_time = (moment.utc(slot.start_time, 'HH:mm')).local().format('HH:mm');
                                slot.end_time = (moment.utc(slot.end_time, 'HH:mm')).local().format('HH:mm');
                            }
                        });
                    }
                }
            }

            callback({success: true, appointment_settings: self.appointment_settings});
        });
    }

    getAppointments(status){
        const self = this;

        let url = 'sec/visitor-appointments';
        let params = {
            property_id: self.property? self.property.id : null,
            response_type: 1,
            include: 'buyers, brokers, users',
            limit: self.limit,
            offset: self.offset,
            send_total_count: 1,
            status: status,
            search_text: self.search_text,
            start_date: self.start_date? self.getParamsDate(self.start_date) : null,
            end_date: self.end_date? self.getParamsDate(self.end_date) : null
        };

        loading(true);
        this.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.success && response.data) {
                self.brokers = response.data.brokers;
                self.buyers = response.data.buyers;
                self.users = response.data.users;

                self.appointments = self.getFormattedAppointments(response.data.visitor_appointments);

                self.prepareData();

                self.count = response.data.total_count;
                self.pages = Math.ceil(self.count / self.limit);
                self.selectedPage = Math.floor(self.offset / self.limit) + 1;
                self.pagesArr = [];

                for (var i = 0; i < self.pages; i++) {
                    self.pagesArr.push(i);
                }
            }
            
            // callback({appointments: self.appointments, total_count: total_count, APPOINTMENTS_DATA: self.APPOINTMENTS_DATA});
        });
    }

    getFilteredAppointments(status){
        const self = this;

        self.searchFilter = status;
        self.start_date = '';
        self.end_date = '';

        if(status == SHOWSUITE.USER_APPOINTMENT_STATUS_EXPIRED){
            self.end_date = moment().format('YYYY-MM-DD HH:mm:ss');
            status = SHOWSUITE.USER_APPOINTMENT_STATUS_SCHEDULED;
        }
        else if(status == SHOWSUITE.USER_APPOINTMENT_STATUS_SCHEDULED){
            self.start_date = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        self.offset = 0;
        self.selectedPage = 1;
        self.getAppointments(status);
    }

    getFormattedAppointments(appointments){
        const self = this;

        if(!appointments){
            return null;
        }

        $.each(appointments, function(index, appointment){

            appointment.start_date = self.formatUtcToLocalDate(appointment.start_date);
            appointment.end_date = self.formatUtcToLocalDate(appointment.end_date);

            if(appointment.status == SHOWSUITE.USER_APPOINTMENT_STATUS_SCHEDULED 
                && (self.searchFilter == '' || self.searchFilter == SHOWSUITE.USER_APPOINTMENT_STATUS_EXPIRED)
                && moment(appointment.end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm') < moment().format('YYYY-MM-DD HH:mm')){
                appointment.status = SHOWSUITE.USER_APPOINTMENT_STATUS_EXPIRED;
            }

            var status = self.getStatus(appointment.status);

            appointment.status_name = status.name;
            appointment.status_class = status._class;

            appointment.created_by_user = self.getUserName(appointment.created_by);

            try{
                // Process brokers
                if(!appointment.hasOwnProperty("brokers")){
                    appointment["brokers"] = [];
                }
                $.each(appointment.broker_user_ids, function(idx, broker_id){
                    let broker = self.users.find(x => x.id == broker_id);
                    let _broker = self.brokers.find(x => x.user_id == broker_id);
                    if(broker){
                        broker.user = broker;
                        broker.name = self.getUserName(broker_id);
                        appointment.brokers.push(broker);

                        if(_broker){
                            let agency = self.agencies.find(x => x.id == _broker.agency_id);
                            broker.agency = agency;
                            broker.reg_no = _broker.reg_no; 
                        }
                    }
                });
            }
            catch(ex){
                console.log(ex);
            }

            try{
                // Process buyers
                if(!appointment.hasOwnProperty("buyers")){
                    appointment["buyers"] = [];
                }
                $.each(appointment.buyer_ids, function(idx, buyer_id){
                    let buyer = self.buyers.find(x => x.id == buyer_id);
                    if(buyer){
                        buyer.name = self.getBuyerName(buyer_id, self.buyers);
                        appointment.buyers.push(buyer);
                    }
                });
            }
            catch(ex){
                console.log(ex);
            }

            // Prepare data for associative array to enable/disable slots
            var date = moment(appointment.start_date).format('YYYY-MM-DD');
            var time = moment(appointment.start_date).format('HH:mm:ss')+ '-' +moment(appointment.end_date).format('HH:mm:ss');
            appointment.date = date;
            appointment.time = time;

            self.APPOINTMENTS_DATA[date] = true;
            self.APPOINTMENTS_DATA[date + '-' + time] = true;
            self.APPOINTMENTS_DATA[date + '-' + time + '-' + appointment.table_no] = true;
        });

        return appointments;
    }

    getAgencies(){
        const self = this;

        let url = 'agencies';
        let params = {
          'format': 1
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.data && response.data.agencies) {
                self.agencies = response.data.agencies;
            }
        });
    }

    prepareData(){
        const self = this;

        self.available_days = [];
        self.blackout_days = {};

        let max_appointment_days = self.appointment_settings.value.max_appointment_days;
        max_appointment_days = max_appointment_days? parseInt(max_appointment_days) : 7;

        let blackout_days = self.appointment_settings.value.blackout_days;
        blackout_days = blackout_days? blackout_days : null;

        $.each(blackout_days, function(index, blackout_day){
            self.blackout_days[blackout_day.date] = blackout_day;
        });

        // Number of tables
        self.tables = [];

        let number_of_tables = self.appointment_settings.value.number_of_tables;
        number_of_tables = number_of_tables? parseInt(number_of_tables) : 1;

        for(var index = 1; index <= number_of_tables; index++){
            self.tables.push({id: index.toString(), name: "SLOT " + index});
        }

        if(max_appointment_days){

            for(var index = 0; index < max_appointment_days; index++){
                
                var date = moment();
                var next_date = date.add(index, 'days');
                // Total slots = time_slots * number_of_tables
                var total_slots = self.getSlotTimes(next_date).length * number_of_tables;

                self.available_days.push({
                    id: index+1, 
                    name: next_date.format('dddd, Do MMMM YYYY'),
                    value: next_date.format('YYYY-MM-DD'),
                    slots: total_slots,
                    available_slots: self.getAvailableSlots(next_date, total_slots),
                    is_blackout_day: self.isBlackoutDay(next_date)
                });

            }
        }
    }

    getSetting(settings, setting_name){
        const self = this;

        if(!(settings || setting_name)){
            return null;
        }

        let setting = {};

        try{
            setting = settings.find(x => x.name == setting_name);

            if(setting && setting.value){
                if(typeof setting.value == "string"){
                    setting.value = JSON.parse(setting.value);
                }
                else{
                    setting.value = setting.value;
                }
            }
        }
        catch(ex){
            console.log(ex);
        }

        return setting;
    }

    getSlotTimes(date){
        const self = this;

        // Slot period in minutes
        let slot_period = self.appointment_settings.value.slot_period;
        slot_period = slot_period? parseInt(slot_period) : 60;

        // Slot times array
        let slot_times = self.appointment_settings.value.slot_times;
        slot_times = (slot_times && slot_times.length > 0)? slot_times : null;

        let _slot_times = [];

        var counter = 0;

        $.each(slot_times, function(index, slot){
            var start_time = moment(slot.start_time,'hh:mm a');
            var end_time = moment(slot.end_time,'hh:mm a');

            while(start_time.format('HH:mm:ss') < end_time.format('HH:mm:ss')){
                counter++;
                _slot_times.push({
                    id: index+counter, 
                    start_time: start_time.format('HH:mm:ss'),
                    name: self.SSUtilService.formatUtcToLocalTime(start_time)+ ' to ' + self.SSUtilService.formatUtcToLocalTime(start_time.add(slot_period, 'minutes')),
                    end_time: start_time.format('HH:mm:ss')
                });
            }

        });

        return _slot_times;
    }

    getAvailableSlots(date, total_slots){
        const self = this;

        $.each(self.appointments, function(index, appointment){
            if(moment(date).format("YYYY-MM-DD") == moment(appointment.start_date).format("YYYY-MM-DD")){
                total_slots--;
            }
        });

        return total_slots;
    }

    isBlackoutDay(date, start_time = null, end_time = null){
        const self = this;

        if(!date || (self.blackout_days && self.blackout_days.length == 0)){
            return false;
        }

        if(typeof date == 'string'){
            date = moment(date);
        }

        let blackout_day = self.blackout_days[date.format('DD/MM/YYYY')];

        if(blackout_day && blackout_day.start_time && blackout_day.end_time){

            start_time = typeof start_time == 'string'? moment(start_time, 'HH:mm:ss') : start_time;
            end_time = typeof end_time == 'string'? moment(end_time, 'HH:mm:ss') : end_time;

            if(start_time >= moment(blackout_day.start_time, 'HH:mm:ss') && end_time <= moment(blackout_day.end_time, 'HH:mm:ss')){
                return true;
            }
            else{
                return false;
            }
        }
        if(blackout_day && !blackout_day.start_time && !blackout_day.end_time){
            return true;
        }

        return false;
    }

    isExpiredSlot(date, start_time, end_time){
        const self = this;

        if(!date || !start_time || !end_time){
            return false;
        }

        start_time = typeof start_time == 'string'? moment(start_time, 'HH:mm:ss') : start_time;

        if(moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
            && start_time.format('HH:mm:ss') < moment().format('HH:mm:ss')){
            return true;
        }
        else{
            return false;
        }

    }

    saveUserAppointment(status) {
        const self = this;

        if(!self.isValidAppointmentInput()){
            return;
        }

        self.appointmentErrors = {};

        let url = 'sec/visitor-appointments';
        let params = self.getAppointmentParams();

        if(status){
            params.status = status;
        }

        let responseHandler = function (response) {
            loading(false);
            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.SSAlertService.success('User appointment updated successfully.');

            self.APPOINTMENT_VIEW_MODE = false;

            self.getAppointments(self.searchFilter);
        };

        loading(true);
        if (params.id) {
            url += '/' + params.id;
            self.SSHttpService.putAPIRequest(url, params).then(responseHandler);
        } else {
            self.SSHttpService.postAPIRequest(url, params).then(responseHandler);
        }
    }

    getAppointmentParams(){
        const self = this;

        let params = angular.copy(self.appointmentParams);

        if(params.buyers){
            params.buyer_ids = [];
            $.each(params.buyers, function(index, buyer){
                params.buyer_ids.push(buyer.id);
            });

            params.buyer_ids = params.buyer_ids? params.buyer_ids.toString() : null;
        }

        if(params.brokers){
            params.broker_user_ids = [];
            $.each(params.brokers, function(index, broker){
                params.broker_user_ids.push(broker.id? broker.id : broker.user_id);
            });

            params.broker_user_ids = params.broker_user_ids? params.broker_user_ids.toString() : null;
        }

        params.start_date = self.getParamsDate(params.start_date);
        params.end_date = self.getParamsDate(params.end_date);

        // To avoid circular structure json conversion issue
        params.brokers = null; 
        params.buyers = null; 
        
        return params;
    }

    isValidAppointmentInput(){
        const self = this;

        let isValid = true;
        self.appointmentErrors = {};

        if(!self.appointmentParams.brokers || self.appointmentParams.brokers.length == 0){
            self.appointmentErrors.brokers = "Please select at least one agnet.";
            isValid = false;
        }

        if(!self.appointmentParams.buyers || self.appointmentParams.buyers.length == 0){
            self.appointmentErrors.buyers = "Please select at least one buyer.";
            isValid = false;
        }

        if(isValid){
            let number_of_persons_per_table = self.appointment_settings.value.number_of_persons_per_table;
            number_of_persons_per_table = number_of_persons_per_table? parseInt(number_of_persons_per_table) : 0;

            if(self.appointmentParams.brokers.length+self.appointmentParams.buyers.length > number_of_persons_per_table){
                self.appointmentErrors.number_of_persons_per_table = "Maximum number of persons should be "+ number_of_persons_per_table;
                isValid = false;
            }
        }

        return isValid;
    }
    
    updateStatus(appointment, status) {
        const self = this;

        if (!appointment || !status) {
            return;
        }

        let update = 'update';
        if (status == self.USER_APPOINTMENT_STATUS_SUCCESSFUL) {
            update = 'mark successful?';
        } else if (status == self.USER_APPOINTMENT_STATUS_CANCELLED) {
            update = 'cancel this?';
        }

        self.SSConfirmationService.getConfirmed(
            'Confirmation!',
            'Are you sure you want to ' + update,
            self.SSConfirmationService.TYPE_DEFAULT, function () {

                let url = 'sec/visitor-appointments/' + appointment.id + '/update-status';
                let params = {
                    status: status,
                    property_id: self.property.id,
                };

                loading(true);
                self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                    loading(false);

                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }

                    if (response.success) {
                        self.SSAlertService.success('Appointment updated successfully.');

                        self.getAppointments(self.searchFilter);
                    }
                });
            });
    }

    deleteAppointment(appointment){
        const self = this;

        if(!appointment){
            return;
        }

        self.SSConfirmationService.getConfirmed(
            'Confirmation!',
            'Are you sure you want to delete this?',
            self.SSConfirmationService.TYPE_DEFAULT,
            function () {
                
                let url = 'sec/visitor-appointments/'+ appointment.id +'/delete';

                loading(true);
                self.SSHttpService.getAPIRequest(url).then(function (response) {
                    loading(false);

                    if (response instanceof Error) {
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }

                    if(response.success){
                        self.SSAlertService.success('Appointment deleted successfully.');
                        self.appointments = $.grep(self.appointments, function(obj){
                            return appointment.id != obj.id;
                        });
                    }
                });

        });
    }

    setStepOneViewMode(appointment, step_number){
        const self = this;

        self.appointmentParams = {};
        self.appointmentErrors = {};

        if(appointment){
            self.appointmentParams = angular.copy(appointment);
        }

        self.appointmentParams.property_id = self.property.id;

        self.APPOINTMENT_VIEW_MODE = true;
        self.slot_times = null;

        self.toggleStepsView(step_number);
    }

    setStepTwoViewMode(date, step_number){
        const self = this;

        self.selected_date = date;
        self.slot_times = self.getSlotTimes(date);

        self.toggleStepsView(step_number);
    }

    setStepThreeViewMode(slot, table_no, step_number){
        const self = this;

        self.selected_slot = slot;

        self.appointmentParams.start_date = self.selected_date.value + " " + slot.start_time;
        self.appointmentParams.end_date = self.selected_date.value + " " + slot.end_time;
        self.appointmentParams.table_no = table_no;

        self.toggleStepsView(step_number);
    }

    toggleStepsView(step_number){
        const self = this;

        self.SHOW_STEP[1] = false;
        self.SHOW_STEP[2] = false;
        self.SHOW_STEP[3] = false;

        self.SHOW_STEP[step_number] = true;
        self.STEP_NUMBER = step_number;
    }
    
    goBack(){
        const self = this;

        self.STEP_NUMBER--;

        if(self.STEP_NUMBER == 0){
            self.APPOINTMENT_VIEW_MODE = false;
        }
        else{
            self.toggleStepsView(self.STEP_NUMBER);
        }
    }

    backPage() {
        const self = this;

        self.selectedPage--;
        self.offset = self.offset - self.limit;
        self.getAppointments(self.searchFilter);
    }

    nextPage() {
        const self = this;

        self.selectedPage++;
        self.offset = self.offset + self.limit;
        self.getAppointments(self.searchFilter);
    }

    gotoPage(pageNum){
        const self = this;

        self.offset = parseInt(pageNum) * self.limit;
        self.getAppointments(self.searchFilter);
    }

    formatDate3(date){
        const self = this;

        return moment(date).format('dddd, Do MMMM YYYY');
    }

    getParamsDate(date){
        const self = this;

        try{
            if(date != '' && date != null) {
                // Split date and time on the basis of white-space
                var date_time = date.split(" ");

                var date = date_time[0];
                var time = '';

                if(date_time.length > 1){
                    time = date_time[1];
                    // Server accepts time as 'hh:mm:ss' but jQuery timepicker is 'hh:mm'
                    if(time.split(':').length == 2){
                        time = time + ':00';
                    }
                }

                if(date.includes("-")){
                    var date_format = date + ' ' + time;
                }
                else{
                    var res = date.split("/", 3);
                    var date_format = res[2] + '-' + res[1] + '-' + res[0] + ' ' + time;
                }

                if(date_format) {
                    // var localDate = new Date(self.parseDate(date_format));
                    var utcFormat = moment(date_format).utc().format('YYYY-MM-DD HH:mm:ss');
                }

                return utcFormat;
            }
            else{
                return null;
            }
        }
        catch(ex){
            console.log(ex);
        }
    }

    getSlotClass(slot, table){
        const self = this;

        let cls = '';

        // If slot falls in 'blackout_day'
        if(self.isBlackoutDay(self.selected_date.value, slot.start_time, slot.end_time)){
            return 'disabled btn-outline-danger';
        }

        // In case of 'Update' 
        if(self.appointmentParams.id){

            if(self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time+'-'+table.id]
                && self.appointmentParams.time == slot.start_time+'-'+slot.end_time
                && self.appointmentParams.table_no == table.id){
                cls += 'highlight';
            }
            else if(self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time+'-'+table.id]){
                cls += 'disabled btn-outline-danger';
            }
        }
        else{
            if(self.APPOINTMENTS_DATA[self.selected_date.value+'-'+slot.start_time+'-'+slot.end_time+'-'+table.id]){
                cls += 'disabled btn-outline-danger';
            }
        }

        return cls;
    }

    getBuyerName(buyer_id, buyers){
        const self = this;

        let name = '--';

        let _buyers = (buyers && buyers.length > 0)? buyers : self.buyers;

        if(!buyer_id || !_buyers){
            return name;
        }

        let buyer = _buyers.find(x => x.id == buyer_id);

        if(buyer && (buyer.individual || buyer.company)){
            name = self.getUserFullName(buyer.individual? buyer.individual : buyer.company);
        }

        return name;
    }

    getAgentName(user_id){
        const self = this;

        let userName = '--';

        if(!user_id || !self.brokers){
            return userName;
        }

        let user = self.brokers.find(x => x.user_id == user_id);
        if(user){
            userName = self.getUserFullName(user);
        }

        return userName;
    }

    getUserName(user_id){
        const self = this;

        let userName = '--';

        if(!user_id || !self.users){
            return userName;
        }

        let user = self.users.find(x => x.id == user_id);
        if(user){
            userName = self.getUserFullName(user);
        }

        return userName;
    }

    getUserFullName(user){
        const self = this;

        let name = "";
        if(user && (user.first_name || user.last_name || user.display_name || user.name)){
            if(user.name){  // In case, if buyer is 'company'
                name = user.name;
            }
            else{
                name = (user.first_name? user.first_name : "") + " " + (user.last_name? user.last_name : "");
            }
            if(!name.trim()){
                name = user.display_name? user.display_name : "";
            }
        }

        return name.trim()? name.trim() : "--";
    }

    removeUser(user_id, user_type){
        const self = this;

        if(!user_id || !user_type){
            return;
        }

        if(user_type == 'broker'){
            self.appointmentParams.brokers = $.grep(self.appointmentParams.brokers, function(obj){
                return user_id != obj.id? obj.id : obj.user_id;
            });
        }

        if(user_type == 'buyer'){
            self.appointmentParams.buyers = $.grep(self.appointmentParams.buyers, function(obj){
                return user_id != obj.id;
            });
        }
    }

    getStatus(status_id){
        const self = this;

        if(!status_id){
            return {name: '--', _class: ''};
        }

        let status = self.statuses.find(x => x.id == status_id);
        if(status){
            return status;
        }

        return {name: '--', _class: ''};
    }

    initStatuses(){
        const self = this;

        self.statuses = [
            {id: SHOWSUITE.USER_APPOINTMENT_STATUS_SCHEDULED, name: 'SCHEDULED', _class: 'text-primary'},
            {id: SHOWSUITE.USER_APPOINTMENT_STATUS_SUCCESSFUL, name: 'SUCCESSFUL', _class: 'text-success'},
            {id: SHOWSUITE.USER_APPOINTMENT_STATUS_CANCELLED, name: 'CANCELLED', _class: 'text-warning'},
            {id: SHOWSUITE.USER_APPOINTMENT_STATUS_EXPIRED, name: 'EXPIRED', _class: 'text-danger'}
        ];
    }

    getParamsDate(date){
        const self = this;

        try{
            if(date != '' && date != null) {
                // Split date and time on the basis of white-space
                var date_time = date.split(" ");

                var date = date_time[0];
                var time = '';

                if(date_time.length > 1){
                    time = date_time[1];
                    // Server accepts time as 'hh:mm:ss' but jQuery timepicker is 'hh:mm'
                    if(time.split(':').length == 2){
                        time = time + ':00';
                    }
                }

                if(date.includes("-")){
                    var date_format = date + ' ' + time;
                }
                else{
                    var res = date.split("/", 3);
                    var date_format = res[2] + '-' + res[1] + '-' + res[0] + ' ' + time;
                }

                if(date_format) {
                    // var localDate = new Date(self.parseDate(date_format));
                    var utcFormat = moment(date_format).utc().format('YYYY-MM-DD HH:mm:ss');
                }

                return utcFormat;
            }
            else{
                return null;
            }
        }
        catch(ex){
            console.log(ex);
        }
    }

    formatUtcToLocalDate(date){
        const self = this;

        var local = date;

        try{
            if(typeof date === 'string'){
                // Convert 'date-time' string to UTC 'date-time' object
                var stillUtc = moment.utc(date).toDate();

                // Now convert UTC to local 'date-time' object
                var local = moment(moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss'));  
            }
        }
        catch(ex){
            console.log(ex);
        }

        return local;
    }
}

SSAppointmentService.$inject = ['SSConfigService', 'SSHttpService', 'SSUtilService', 'SSAlertService', 'SSConfirmationService'];
app.service('SSAppointmentService', SSAppointmentService);
