"use strict";
class SSHttpService {

    constructor($http, $window, SSUtilService, SSConfigService, SSStorageService) {
        const self = this;

        self.$http = $http;
        self.$window = $window;

        self.SSUtilService = SSUtilService;
        self.SSConfigService = SSConfigService;
        self.SSStorageService = SSStorageService;

        self.setBasicAuth();
    }

    setBasicAuth() {
        const self = this;

        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        let isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

        if ((isSafari || isIE) && APP_CONFIG.basicAuth) {
            let basicauth = self.SSStorageService.getFromSessionStorage('basicauth');

            if (!(basicauth && basicauth.username && basicauth.password)) {
                basicauth = {};
                basicauth.username = prompt('Authentication Required\nUsername:');
                basicauth.password = prompt('Authentication Required\nPassword:');

                if (!basicauth.username || !basicauth.password) {
                    self.$window.location.reload();
                }
            }

            self.$http.defaults.headers.common['Authorization'] = 'Basic ' + btoa(basicauth.username + ':' + basicauth.password);

            self.SSStorageService.saveToSessionStorage('basicauth', basicauth);
        }
    }

    /**
     * Get Request to get data from specified URL of Server
     * @param url
     * @param params
     * @param headers
     */
    getRequest(url, params = null, headers = null) {
        return this.sendRequest('GET', this.getServerURL(url), params, headers);
    }

    postRequest(url, params = null, headers = null) {
        return this.sendRequest('POST', this.getServerURL(url), params, headers);
    }

    putRequest(url, params = null, headers = null) {
        return this.sendRequest('PUT', this.getServerURL(url), params, headers);
    }

    /**
     * Delete Request to delete data from specified URL of Server
     * @param url
     * @param params
     */
    deleteRequest(url, params = null, headers = null) {
        return this.sendRequest('DELETE', this.getServerURL(url), params, headers);
    }

    /**
     * Get Request to get data from specified URL of API
     * @param url
     * @param params
     * @param headers
     */
    getAPIRequest(url, params = null, headers = null) {
        return this.sendRequest('GET', this.getApiURL(url), params, headers);
    }

    postAPIRequest(url, params = null, headers = null) {
        return this.sendRequest('POST', this.getApiURL(url), params, headers);
    }

    putAPIRequest(url, params=null, headers = null) {
        return this.sendRequest('PUT', this.getApiURL(url), params, headers);
    }

    deleteAPIRequest(url, params=null, headers=null) {
        return this.sendRequest('DELETE', this.getApiURL(url), params, headers);
    }

    sendRequest(method, url, params=null, headers=null) {
        let self = this;

        if (!params) {
            params = {}; // Null handling
        } else if (Array.isArray(params)) {
            params = {body: params}; // Convert body array data to dictionary
        }
        params.device_uuid = this.SSUtilService.localStorageUUID();

        let apiToken = this.getUserApiToken();
        url += (url.indexOf('?') === -1 ? '?' : '&');
        url += 'api_token=' + apiToken;

        let urlArray = url.split('?');
        url = urlArray[0];

        if (urlArray.length > 1) {
            let urlParams = urlArray[1];

            $.each(urlParams.split('&'), function (i, p) {
                let pArray = p.split('=');
                params[pArray[0]] = pArray[1];
            });
        }

        if (self.SSConfigService
            && self.SSConfigService.serverConfig
            && self.SSConfigService.serverConfig.params_encoding_enabled
        ) {
            // Config as per env settings.
            params = encodeParams(params);
        }

        if (method=='POST') {
            // Params are sent as payload
            return this.$http.post(url, params, {withCredentials: true})
                .then(this.requestSuccess.bind(this), this.requestFailed.bind(this));
        } else if (method=='PUT') {
            // Params are sent as payload
            return this.$http.put(url, params, {withCredentials: true})
                .then(this.requestSuccess.bind(this), this.requestFailed.bind(this));
        } else {
            // Params are sent as querystring
            return this.$http({
                url: url,
                method: method,
                withCredentials: true,
                headers,
                params
            }).then(this.requestSuccess.bind(this), this.requestFailed.bind(this));
        }
    }

    requestSuccess(responseObj, obj) {
        return this.validateResponse(responseObj);
    }

    requestFailed(responseObj, obj) {
        return this.validateResponse(responseObj);
    }

    validateResponse(responseObj) {
        //this.$log.log(responseObj);

        // Add specific check for timeout
        if (responseObj && responseObj.status === 504) {
            return responseObj;
        }

        //check if success flag return in response, a compulsory object for SHowSuite valid response
        if (!_.has(responseObj, 'data.success')) {
            return this.makeErrorObject("Invalid data, no success status found", responseObj);
        }

        if (responseObj.data.success == false) {
            return this.makeErrorObject("Success status is false from server ", responseObj);
        }

        //return core object of $http so can read .success directly.
        return responseObj.data;

    }

    makeErrorObject(message, responseObj) {
        let errorObj = new Error(message);

        if (_.has(responseObj, 'data.code')) {
            errorObj.code = responseObj.data.code;
        }

        if (_.has(responseObj, 'data.sub_code')) {
            errorObj.sub_code = responseObj.data.sub_code;
        }

        if (_.has(responseObj, 'data.message')) {
            errorObj.message = responseObj.data.message;
        }

        if (_.has(responseObj, 'data.data')) {
            errorObj.data = responseObj.data.data;
        }

        this.SSUtilService.logError(errorObj);
        return errorObj;
    }

    parseErrors(responseObj) {
        let errors = {};

        jQuery.each(responseObj.data, function (key) {
            try {
                errors[key] = responseObj.data[key][0];
            } catch (ex) {
            }
        });

        responseObj.data = errors;

        return responseObj.data;
    }

    getApiURL(url) {
        const self = this;

        if (SHOWSUITE
            && SHOWSUITE.data
            && SHOWSUITE.data.api_server
            && SHOWSUITE.data.api_server.api_url
            && SHOWSUITE.data.api_server.api_version
        ) {
            url = SHOWSUITE.data.api_server.api_url + '/api/' + SHOWSUITE.data.api_server.api_version + '/' + url;
        } else {
            url = self.$window.location.origin + '/api/1.0/' + url;
        }

        let apiToken = this.getUserApiToken();
        if (url.indexOf('?') === -1) {
            url += '?api_token=' + apiToken;
        } else {
            url += '&api_token=' + apiToken;
        }

        return url;
    }

    getServerURL(url) {
        const self = this;

        if (SHOWSUITE && SHOWSUITE.data && SHOWSUITE.data.api_server && SHOWSUITE.data.api_server.api_url && SHOWSUITE.data.api_server.api_version) {
            return SHOWSUITE.data.api_server.api_url + '/' + url;
        } else {
            return self.$window.location.origin + url;
        }
    }

    getSGVerifyURL(url) {
        const self = this;

        return SHOWSUITE.SGVERIFY_API_URL + '/' + url;
    }

    getSGVerifyRequest(url, params = null, headers = null) {
        let vUrl = this.getSGVerifyURL(url);

        if (!params) params = {};
        // params.device_uuid = this.SSUtilService.localStorageUUID();

        let request = {
            url: vUrl,
            method: "GET",
            withCredentials: false,
            headers,
            params
        };

        return this.$http(request)
            .then(function successCallback(response) {
                return response;
            }, function errorCallback(response) {
                return response;
            });
    }

    getUserApiToken() {
        let user = this.SSUtilService.getFromLocalStorage('user');
        return user ? user.api_token : null;
    }

    //-------------------------------------------

    getAddressFromPostcode(postcode, callback) {
        $.ajax({
            // url: 'https://developers.onemap.sg/commonapi/search?searchVal=' + postcode + '&returnGeom=N&getAddrDetails=Y&pageNum=1', // old
            url: 'https://www.onemap.gov.sg/api/common/elastic/search?searchVal=' + postcode + '&returnGeom=Y&getAddrDetails=Y&pageNum=1',
            success: function (response) {
                let result = null;
                if (response && response.results && response.results.length > 0) {
                    result = response.results[0];
                }
                callback(result);
            }
        });
    }
    //-------------------------------------------

}

SSHttpService.$inject = ['$http', '$window', 'SSUtilService', 'SSConfigService', 'SSStorageService'];
app.service('SSHttpService', SSHttpService);